import React from "react";
// import { useTranslation } from "react-i18next";
import "./styles/App.css";
import { RouterConfig } from "./routes/RouterConfig";
function App() {
  // const { i18n } = useTranslation();

  // React.useEffect(() => {
  //   const language = localStorage.getItem("language") || "es";
  //   if (
  //     language !== undefined &&
  //     language !== null &&
  //     language !== "" &&
  //     language !== "null"
  //   ) {
  //     i18n.changeLanguage(language);
  //   }
  // },[]);

  return (
  
<RouterConfig />
  
      
    
  );
}

export default App;
