import { Navigate } from "react-router-dom";
import { AuthServices } from "../services/Auth.services";

export default function useAuth() {
  const user = AuthServices.getToken();
  if (user) {
    return true;
  } else {
    return false;
  }
}

export const PrivateRoute = ({ children }) => {
  const auth = useAuth();
  return auth ? <div>{children}</div> : <Navigate to="/app" />;
};
