import { baseURL } from "../../../services/baseURL";
import axios from "axios";
import { toast } from "react-hot-toast";

export const updateCompany = async (data) => {
  try {
    const response = await axios({
      url: `${baseURL}/editarcompania`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    toast.success("Se han editado exitosamente los datos");
    return response;
  } catch (error) {
    toast.error("Ha ocurrido un error comuniquese con el administrador");
  }
};
