import { baseURL } from "../../../services/baseURL";
import axios from "axios";
import { toast } from "react-hot-toast";

export const PostInviteUser = async (data) => {
  try {
    const response = await axios({
      url: `${baseURL}/invitarusuario`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    toast.success("El usuario ha sido invitado,comuniquese con el administrador")
    return response;
  } catch (error) {
    console.log(error);
  }
};
