import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TiWarningOutline } from "react-icons/ti";
import { createTema } from "../services/createTema";
import { ErrorMessage } from "@hookform/error-message";
import { toast, Toaster } from "react-hot-toast";
import { RiAddCircleLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { baseURL } from "../../../services/baseURL";
import { cloneDeep } from "lodash";

import axios from "axios";

export const ModalTemasNoGRI = ({ setTemaNoGRI}) => {
  const [showModal, setShowModal] = useState(false);
  const NTipoSector = ["Social", "Ambiental", "0", "Económico"];

  const {
    register,
    handleSubmit,
    formState,
    reset,
    formState: { errors },
    control,
  } = useForm({ mode: "onChange" });

 

  async function update() {
    const res = await createTema();
    return res.status === 200
      ? await axios
          .get(`${baseURL}/getadicional`)
          .then((res) => {
            let data = res.data;
            let objets = { social: [], ambiental: [], economico: [] };
            let equivs = {
              Social: "social",
              Ambiental: "ambiental",
              Económico: "economico",
            };

            NTipoSector.forEach((el) => {
              let test = [];
              const dataValueNTipo = cloneDeep(data);
              let renderNTipo = dataValueNTipo.filter(
                (key) => key.NTipo === el
              );
              let uniqueValueNTema = [
                ...new Set(renderNTipo.map((a) => a.NTema)),
              ];

              uniqueValueNTema.forEach((el2) => {
                let z = renderNTipo.filter((val) => val.NTema === el2);
                test.push({ topic: el2, content: z });
              });
              objets[equivs[el]] = test;
            });
            toast.success("El tema se ha creado exitosamente");
            setTemaNoGRI(objets);
          })
          .catch((error) => {
            toast.error("Dirección de correo , no registrado")
          })
      : null;
  }

  function openModal(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowModal(true);
  }

  function closeModal(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowModal(false);
  }

  const FormOnSubmit = (data, e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    update(data);
    createTema(data);
    setTimeout(() => setShowModal(false), 1500);
  };

  return (
    <>
      <div className="flex items-center absolute h-12 p-4 left-14 titelAccordionHeader text-base gap-0.5 pr-5 cursor-pointer">
        <IconContext.Provider value={{ color: "grey", size: "30px" }}>
          <RiAddCircleLine
            onClick={openModal}
            title="Agregar Tema perzonalizado"
            className=""
          />
        </IconContext.Provider>
        Crear Tema No GRI
      </div>

      {showModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"> </div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative  max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex justify-end p-2 border-b border-solid border-gray-300 rounded-t ">
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={closeModal}
                  >
                    <span className="text-black opacity-7 h-8 w-8 text-xl block bg-zinc-200 py-0 rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative flex-auto">
                  <form
                    onSubmit={handleSubmit(FormOnSubmit)}
                    className="rounded px-8 pt-6  w-full"
                  >
                    <label
                      htmlFor="NTema"
                      className="block text-sm font-semibold py-2.5  text-start secondary-title"
                    >
                      Título del tema material
                    </label>
                    <input
                      type="text"
                      required
                      name="NTema"
                      className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                        errors.NTema &&
                        "focus:border-red-500 focus:ring-red-500 border-red-500"
                      }`}
                      {...register("NTema", {
                        minLength: {
                          value: 3,
                          message:
                            "Título del tema debe tener al menos 3 caracteres",
                        },
                        pattern: true,
                      })}
                      placeholder="Título del tema"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="NTema"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <div className="flex justify-start py-1 gap-0.5">
                                {" "}
                                <TiWarningOutline className="text-red-500" />
                                <small
                                  className="text-xs text-start text-red-500 message"
                                  key={type}
                                >
                                  {message}
                                </small>
                              </div>
                            ))
                          : null;
                      }}
                    />
                    {/** */}
                    <label
                      htmlFor="NIndicador"
                      className="block text-sm font-semibold py-2.5  text-start secondary-title"
                    >
                      Descripción del tema
                    </label>
                    <input
                      type="text"
                      required
                      name="NIndicador"
                      className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                        errors.NIndicador &&
                        "focus:border-red-500 focus:ring-red-500 border-red-500"
                      }`}
                      {...register("NIndicador", {
                        minLength: {
                          value: 3,
                          message:
                            "Título del tema debe tener al menos 3 caracteres",
                        },
                        pattern: true,
                      })}
                      placeholder="Nombre del tema a medir"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="NIndicador"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <div className="flex justify-start py-1 gap-0.5">
                                {" "}
                                <TiWarningOutline className="text-red-500" />
                                <small
                                  className="text-xs text-start text-red-500 message"
                                  key={type}
                                >
                                  {message}
                                </small>
                              </div>
                            ))
                          : null;
                      }}
                    />

                    {/** */}
                    <label
                      htmlFor="NTipo"
                      className="block text-sm font-semibold py-2.5  text-start secondary-title"
                    >
                      Tipo de Estándar
                    </label>
                    <select
                      {...register("NTipo")}
                      name="NTipo"
                      className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                        errors.NTipo &&
                        "focus:border-red-500 focus:ring-red-500 border-red-500"
                      }`}
                      {...register("NTipo", {
                        minLength: {
                          value: 3,
                          message:
                            "Título del tema debe tener al menos 3 caracteres",
                        },
                        pattern: true,
                      })}
                      placeholder="Nombre del tema a medir"
                    >
                      <option defaultValue="default">
                        Seleccione tipo de Estándar
                      </option>
                      <option value="Económico">Económico</option>
                      <option value="Ambiental">Ambiental</option>
                      <option value="Social">Social</option>
                    </select>

                    <ErrorMessage
                      errors={errors}
                      name="NTipo"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <div className="flex justify-start py-1 gap-0.5">
                                {" "}
                                <TiWarningOutline className="text-red-500" />
                                <small
                                  className="text-xs text-start text-red-500 message"
                                  key={type}
                                >
                                  {message}
                                </small>
                              </div>
                            ))
                          : null;
                      }}
                    />
                    <div class="btn-register px-7 py-7 tracking-wide  text-white font-semibold focus:bg-color-btn transition-colors duration-200 transform">
                      {" "}
                      <button type="submit" class="boxshadow">
                        Registrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
          </div>

          <Toaster position="top-right" />
        </>
      ) : null}
    </>
  );
};