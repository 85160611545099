import { baseURL } from "../../../services/baseURL";
import axios from "axios";
import { toast } from "react-hot-toast";


export const dataProfile = async (data) => {

    try{
    const response = await axios({
     url:`${baseURL}/editarusuario`,
     data,
     method:"POST",
     headers: { "Content-Type": "application/json" },

    })
    toast.success(`Su información ha sido edita exitosamente.`)
    return response;
    }catch(error){
    toast.error(`El correo ya ha sido utilizado, inserte un nuevo correo`)
    }
}
