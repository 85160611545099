import axios from "axios";
import { baseURL } from "../../../services/baseURL";

export const Plantillas = (data) => {
  axios
    .post(`${baseURL}/registrarplantilla`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
