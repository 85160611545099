import { useEffect, useState } from "react";
// import { changeLanguage, t } from "i18next";
import { Link, NavLink } from "react-router-dom";
import { ModalExencion } from "../../pages/TemplateG/component/ModalExencion";
import { AuthServices } from "../../services/Auth.services";
import SECO from "../../assets/images/SECO.jpg"
import avatar from "../../assets/images/avatar.jpg"
import {FaHome,FaFileDownload, FaFolder,FaUserEdit,FaIndustry,FaUserPlus,FaSignOutAlt} from "react-icons/fa"
import {FiDownload} from "react-icons/fi"



function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [toggleNabvar, setToggleNabvar] = useState(false);
  const visible = { visibility: toggle ? "visible" : "hidden" };
  const visibleNavbar = { visibility: toggleNabvar ? "visible" : "hidden" };
  const [showModalExencion, setShowModalExencion] = useState(false);


  function toggleHidden() {
    setToggle(!toggle);
  }

  function Navbar() {
    setToggleNabvar(!toggleNabvar);
  }



  let activeStyle = {
    textDecoration: "underline",
    textDecorationColor: "#fffff"
  };

  //Esta funcion se debera utilizar cuando se implemente el cambio de lenguaje
  // function toggleLanguage() {
  //   if (localStorage.getItem("language") === "es") {
  //     localStorage.setItem("language", "pt");
  //   } else {
  //     localStorage.setItem("language", "es");
  //   }
  //   changeLanguage(localStorage.getItem("language"));
  // }

  return (
    <>
      <nav className="bg-white">
      <div className="content-header px-2 sm:px-6 lg:px-8">
        <div className="relative border-b-2 flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-between">
            <div className="flex flex-shrink-0 items-center">
            <img
                className="block h-8 w-auto lg:hidden"
                src={SECO}
                alt="Your Company"
              />
              <NavLink to={"/app/home"}>
              <img
                className="block h-8 w-auto lg:hidden"
                src="https://www.globalreporting.org/styles/assets/svg/GRI_Master_Logo-solo.svg"
                alt="Your Company"
                
              />
              </NavLink>
             
               <img
                className="hidden h-8 w-auto lg:block"
                src={SECO}
                alt="Your Company"
              />
              <img
                className="hidden h-8 w-auto lg:block"
                src="https://www.globalreporting.org/styles/assets/svg/GRI_Master_Logo-solo.svg"
                alt="Your Company"
                to={"/app/home"}
              />
            </div>
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  to={"/app/home"}
                  end
                  activeclassname="active"
                  className=" transform transition duration-500 hover:scale-100 hover:text-slate-400 text-black   text-title px-3 py-2 border-b-2   text-m  font-medium  border-transparent"
                  aria-current="page"
                >
                  Inicio
                </NavLink>
                <a
                  href="https://www.globalreporting.org/how-to-use-the-gri-standards/gri-standards-spanish-translations/" target="_blank"
                  className="transform transition cursor-pointer duration-500 hover:scale-100 hover:text-slate-400 text-black   text-title px-3 py-2 border-b-2   text-m  font-medium  border-transparent"
                >
                  Descarga Estándares GRI
                </a>
                <NavLink
                  onClick={() => setShowModalExencion(true)}
                  className="transform transition duration-500 hover:scale-100 hover:text-slate-400 text-black   text-title px-3 py-2 border-b-2   text-m  font-medium  border-transparent"
                >
                 Recursos
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  to={"/app/Plantillas-GRI"}
                  className="transform transition duration-500 hover:scale-100 hover:text-slate-400 text-black   text-title px-3 py-2 border-b-2   text-m  font-medium  border-transparent"
                >
                  Generador de Plantillas
                </NavLink>
              </div>
            </div>
          </div>
          <div className="inset-y-0 right-0 flex items-star pr-2   sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <div>
                <button
                  onClick={(e) => toggleHidden(e)}
                  type="button"
                  className="flex rounded-full bg-withe text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 "
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src={avatar}
                    alt=""
                  />
                </button>
              </div>

              <div
                style={visible}
                className="absolute right-0 z-10 mt-2 w-64 mt-4 px-1.5 origin-top-right content-mobile-menu rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex="-1"
              >
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  to="/app/edituser"
                  activeclassname="active"
                  className="text-title flex items-center gap-1 transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-0"
                >
                  <FaUserEdit/>
                  Editar Usuario
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  activeclassname="active"
                  to="/app/editcompany"
                  className="text-title flex items-center gap-1 transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-0"
                >
                  <FaIndustry/>
                  Editar Compañía
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  activeclassname="active"
                  to="/app/inviteuser"
                  className="text-title flex items-center gap-1 transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-1"
                >
                  <FaUserPlus/>
                  Invitar Usuario
                </NavLink>
                <NavLink
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  activeclassname="active"
                  to="/app"
                  className="text-title  flex items-center gap-1 transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2  text-sm font-medium border-t-2"
                  role="menuitem"
                  tabIndex="-1"
                  id="user-menu-item-2"
                  onClick={() => {
                    AuthServices.deleteToken();
                    sessionStorage.removeItem('mostrarModal')
                  }}
                >
                  <FaSignOutAlt/>
                  Cerrar sesión
                </NavLink>
              </div>
            </div>
          </div>

          <div className="inset-y-0 right-0 flex items-star pr-2  sm:hidden  sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <div>
                <button
                  onClick={(e) => Navbar(e)}
                  type="button"
                  className="flex rounded-full bg-withe text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  id="mobile-menu"
              aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span className="sr-only">Open user menu</span>
                  <svg
               
               className="block icon-block h-6 w-6"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth="1.5"
               stroke="currentColor"
               aria-hidden="true"
             >
               <path
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
               />
             </svg>
             <svg
               className="hidden h-6 w-6"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               strokeWidth="1.5"
               
               aria-hidden="true"
             >
             </svg>
                </button>
              </div>
              <div
                style={visibleNavbar}
                className="absolute text-sm right-0 z-10 mt-8 w-72 origin-top-right content-mobile-menu rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="mobile-menu"
                tabIndex="-1"
              >
               <NavLink
           style={({ isActive }) => (isActive ? activeStyle : undefined)}
           activeclassname="active"
           to={"/app/home"}
            className="text-title flex items-center gap-1 transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
            aria-current="page"
          >
            <FaHome/>
           Inicio
          </NavLink>

          <a
           activeclassname="active"
           href="https://www.globalreporting.org/how-to-use-the-gri-standards/gri-standards-spanish-translations/" target="_blank"
            className="text-title flex items-center cursor-pointer gap-1  transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
          >
            <FaFileDownload/>
            Descarga estándares GRI
          </a>

          <NavLink
           activeclassname="active"
           onClick={() => setShowModalExencion(true)}
            className="text-title flex items-center gap-1   transform transition duration-500 hover:scale-100 hover:text-slate-400  block px-3 py-2 rounded-md text-sm font-medium"
          >
           
            
            <FaFolder/>
           
           
            Recursos
          </NavLink>

          <NavLink
           style={({ isActive }) => (isActive ? activeStyle : undefined)}
           activeclassname="active"
            to="/app/Plantillas-GRI"
            className="text-title flex items-center gap-1  transform transition duration-500 hover:scale-100 hover:text-slate-400 block px-3 py-2 rounded-md text-sm font-medium"
          >
          
          <FiDownload/>
           
            Generador de plantillas
          </NavLink>
              </div>
            </div>
          </div>

        </div>
      </div>

     
    </nav>
    <ModalExencion showModalExencion={showModalExencion} setShowModalExencion={setShowModalExencion}/>
    
    </>
  
    
  );
}

export default Navbar;
