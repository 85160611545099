import sector from "../../services/sector.json";
import country from "../../services/country.json";
import PhoneInput from "react-phone-number-input";
import { Footer } from "../../layouts/Footer";
import Navbar from "../../components/common/navbar";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { IconContext } from "react-icons";
import { useState, useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Toaster } from "react-hot-toast";
import { TiWarningOutline } from "react-icons/ti";
import { updateCompany } from "./services/update.services";
import { GetValueUser } from "../TemplateG/services/updateTemas.services";
import { AuthServices } from "../../services/Auth.services";
import {
  getSocios,
  getGeoInfo,
} from "../RegisterUser/services/getsocio.services";
import Image1 from "../../assets/images/Image1.jpg";
import Image2 from "../../assets/images/Image2.jpg";
import Image3 from "../../assets/images/Image3.jpg";
import Image4 from "../../assets/images/Image4.jpg";
import Image5 from "../../assets/images/Image5.jpg";

export const EditCompany = () => {
  const [categorie, setCategorie] = useState([]);
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState("PE");

  /*handle events*/
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      NAncla: "",
      NCompania: "",
      NEstado: "",
      NPais: "",
      NSector: "",
      NSocio: "",
      NTelefono: "",
      NWeb: "",
      NTipo: "",
      NImagen: "",
    },
  });

  async function updateInfoCompany() {
    const response = await GetValueUser();
    let defaultValue = response[0];
    reset({ ...defaultValue });
  }

  async function loadSocio() {
    const response = await getSocios().then((res) => {
      categorieCountry(res.data);
    });
    return response;
  }

  const categorieCountry = (socioData) => {
    let objCountry = {};
    socioData.forEach((item) => {
      if (!objCountry.hasOwnProperty(item.pais)) {
        objCountry[item.pais] = [];
      }

      objCountry[item.pais].push({
        name: item.name,
        id: item.id,
      });
    });
    setCategorie(objCountry);
  };

  async function getInfo() {
    const response = await getGeoInfo();
    let data = response.data;
    setCountryCode(data.country_calling_code);
  }

  useEffect(() => {
    getInfo();
    AuthServices.axiosInterceptors();

    loadSocio();
    updateInfoCompany();
  }, []);

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    });
  };

  /*handle submit events*/
  const onFormSubmit = (data) => {
    updateCompany(data);
  };

  return (
    <>
      <Navbar />
      <main className="grid  container-form">
        <h1 className="font-bold p-2 text-center primary-title">
          Editar Compañía
        </h1>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="mt-6 grid form-editCompany"
        >
          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NCompania"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Razón Social
            </label>
            <div>
              <input
                defaultValue={value.NCompania}
                type="text"
                name="NCompania"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NCompania &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NCompania", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "Razón social debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "Razón social debe tener como máximo 100 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Razón social"
              />
            </div>
            {errors.NCompania && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NCompania.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NSector"
              className=" bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Sector{" "}
            </label>

            <div className="relative">
              <select
                defaultValue={value.NSector}
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NSector &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NSector", { required: "País es requerido" })}
              >
                <option defaultValue="default">Seleccione Sector</option>
                {sector.map((e, key) => {
                  return (
                    <option key={key} className="text-sm">
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>
            {errors.NSector && (
              <span className="text-xs text-start text-red-500">
                {errors.NSector.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NSocio"
              className=" bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Socio implementador{" "}
            </label>
            <div className="relative">
              <select
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NSocio &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NSocio", {
                  required: "Socio implementador es requerido",
                })}
              >
                <option defaultValue="default">Seleccione socio</option>
                {Object.keys(categorie).map((pais, index) => {
                  return (
                    <optgroup key={index} label={pais}>
                      {renderOptions(categorie[pais])}
                    </optgroup>
                  );
                })}
              </select>
            </div>

            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>
            {errors.NSocio && (
              <span className="text-xs text-start text-red-500">
                {errors.NSocio.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NTelefono"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Teléfono{" "}
            </label>
            <Controller
              defaultCountry={value.NTelefono}
              name="NTelefono"
              control={control}
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NTelefono &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NTelefono", {
                message:
                  "Número de teléfono no puede ser inferior ó mayor a 9 dígitos",
                pattern: "/^[0-9]+$/i",
              })}
              rules={{ validate: (value) => isValidPhoneNumber(value) }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  defaultCountry={countryCode}
                  international
                  value={value}
                  onChange={onChange}
                  rules={{ required: true }}
                />
              )}
            />
            {errors["NTelefono"] && (
              <span className="text-xs text-start text-red-500">
                Número de teléfono es requerido
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NPais"
              className=" bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              País{" "}
            </label>
            <div className="relative">
              <select
                defaultValue={value.NPais}
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NPais &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NPais", { required: "País es requerido" })}
              >
                <option defaultValue="default">Seleccione país</option>
                {country.map((e, key) => {
                  return (
                    <option key={key} className="text-sm" value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>

            {errors.NPais && (
              <span className="text-xs text-start text-red-500">
                {errors.NPais.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NWeb"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Página web{" "}
            </label>
            <div>
              <input
                defaultValue={value.NWeb}
                type="text"
                name="NWeb"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NWeb &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NWeb", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "La página web debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "La página web debe tener como máximo 100 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Página web"
              />
            </div>
            {errors.NWeb && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NWeb.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NAncla"
              className="block text-sm text-start font-semibold  secondary-title"
            >
              {" "}
              Empresa ancla (opcional)
            </label>
            <div>
              <input
                defaultValue={value.NAncla}
                type="text"
                name="NAncla"
                {...register("NAncla", {
                  minLength: {
                    value: 3,
                    message: "Empresa ancla debe tener al mennos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "Empresa ancla debe tener como máximo 100 caracteres",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                className="block w-full px-3 py-2 mt-2  bg-white border bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs"
                placeholder="Empresa ancla"
              />
            </div>
            {errors.NAncla && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NAncla.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NTipo"
              className=" bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Tipo de reporte
            </label>
            <div className="relative">
              <select
                defaultValue={value.NTipo}
                className="block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs"
                {...register("NTipo")}
              >
                <option defaultValue="default">Seleccione país</option>
                <option className="text-sm" value="1">
                  De conformidad con los Estándares GRI
                </option>
                <option className="text-sm" value="2">
                  GRI Referenciado
                </option>
              </select>
            </div>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>
          </div>

          <div className="mb-2 flex flex-col NImagen-GRI justify-center">
            <label
              htmlFor="NImagen"
              className=" bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Tipo de imagen
            </label>

            <div className="flex flex-wrap gap-1 pt-2">
              <div className="type-imagesGRI">
                <img
                  className="GRI-image rounded w-16"
                  alt="image1"
                  src={Image1}
                />
                <input
                  type="radio"
                  className="absolute  cursor-pointer NImagen"
                  value="1"
                  {...register("NImagen")}
                  required
                />
              </div>
              <div className="type-imagesGRI">
                <img className="GRI-image w-16" alt="image2" src={Image2} />
                <input
                  type="radio"
                  className="absolute  cursor-pointer NImagen"
                  value="2"
                  {...register("NImagen")}
                  required
                />
              </div>
              <div className="type-imagesGRI">
                <img className="GRI-image w-16" src={Image3} alt="griImage" />
                <input
                  type="radio"
                  className="absolute  cursor-pointer NImagen"
                  value="3"
                  {...register("NImagen")}
                  required
                />
              </div>
              <div className="type-imagesGRI">
                <img className="GRI-image w-16" src={Image4} />
                <input
                  type="radio"
                  className="absolute cursor-pointer NImagen"
                  value="4"
                  {...register("NImagen")}
                  required
                />
              </div>
              <div className="type-imagesGRI">
                <img className="GRI-image w-16" src={Image5} alt="griImage2" />
                <input
                  type="radio"
                  className="absolute  cursor-pointer NImagen"
                  value="5"
                  {...register("NImagen")}
                  required
                />
              </div>
            </div>
          </div>

          <div className="btn-register mt-2 px-3 py-2 tracking-wide  text-white font-semibold focus:bg-color-btn transition-colors duration-200 transform">
            {" "}
            <button type="submit" className="boxshadow">
              Guardar
            </button>
          </div>
        </form>
      </main>
      <Toaster position="top-right" />
      <Footer />
    </>
  );
};
