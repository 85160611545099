import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

var counter = 1;
var counter2 = 10000;

export const generateDocument = (
  InputData,
  periodo,
  fecha1,
  fecha2,
  fecha3,
  empresa,
  tipo,
  imagen,
  sector,
  isSectorial,
  arraySectorial,
  TemaNoGRI,
  setShowLoader,
  setShowModalDisclaimer,
  toast
) => {
  function parser(tag) {
    // We write an exception to handle the tag "$pageBreakExceptLast"
    if (tag === "$pageBreakExceptLast") {
      return {
        get(scope, context) {
          const totalLength =
            context.scopePathLength[context.scopePathLength.length - 1];
          const index = context.scopePathItem[context.scopePathItem.length - 1];
          const isLast = index === totalLength - 1;
          if (!isLast) {
            return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
          } else {
            return "";
          }
        },
      };
    }
    return {
      get: function (scope) {
        // scope will be {user: "John"}
        if (tag === ".") {
          return scope;
        } else {
          // Here we return the property "user" of the object {user: "John"}
          return scope[tag];
        }
      },
    };
  }

  // poner el año en periodo de fecha

  let data = {
    isSectorial,
    PERIODO: periodo,
    FECHA1: fecha1,
    FECHA2: fecha2,
    FECHA3: fecha3,
    EMPRESA: empresa,
    TIPO: tipo,
    sector: sector,
    SECCION1: [],
    SECCION2: [],
    SECCION3: [],
    SECCION4: [],
    SECCION5: [],
    SECCION6: [],
    SECCION7: [],
    NOGRI5: [],
    NOGRI6: [],
    NOGRI7: [],
    INDICE: [],
  };

  let ObjHelper = {};
  let ObjHelperNoGRI = {};
  let ObjHelperIndice = {};
  let ObjHelperIndiceNOGRI = {};


  for (var keyInputData in InputData) {
    if (Object.hasOwnProperty.call(InputData, keyInputData)) {
      const element = InputData[keyInputData];
      element.forEach((elementInputData) => {
        elementInputData.content.forEach((content) => {
          if (content.isChecked) {
            let seccion = "SECCION" + content.NSeccion.substring(0, 1);
            let tema = content.NTema;

            if (!ObjHelper[seccion]) {
              ObjHelper[seccion] = {};
            }
            if (!ObjHelper[seccion][content.name]) {
              ObjHelper[seccion][content.name] = [];
            }
            ObjHelper[seccion][content.name].push(content);

            if (!ObjHelperIndice[tema]) {
              ObjHelperIndice[tema] = [];
            }

            ObjHelperIndice[tema].push(content);
          }
        });
      });
    }
  }

  arraySectorial.forEach((sectorial) => {
    let seccion = "SECCION" + sectorial.NSeccion.substring(0, 1);
    let tema = sectorial.NTema;

    if (!ObjHelper[seccion]) {
      ObjHelper[seccion] = {};
    }
    if (!ObjHelper[seccion][sectorial.name]) {
      ObjHelper[seccion][sectorial.name] = [];
    }

    ObjHelper[seccion][sectorial.name].push(sectorial);

    if (!ObjHelperIndice[tema]) {
      ObjHelperIndice[tema] = [];
    }
    ObjHelperIndice[tema].push(sectorial);
  });

  for (var keyTemaNoGRI in TemaNoGRI) {
    if (Object.hasOwnProperty.call(TemaNoGRI, keyTemaNoGRI)) {
      const element = TemaNoGRI[keyTemaNoGRI];
      element.forEach((temaNoGRI) => {
        temaNoGRI.content.forEach((contentNoGRI) => {
          if (contentNoGRI.isChecked) {
            let seccion = "NOGRI" + contentNoGRI.NSeccion.substring(0, 1);
            let tema = contentNoGRI.NTema;

            if (!ObjHelperNoGRI[seccion]) {
              ObjHelperNoGRI[seccion] = {};
            }
            if (!ObjHelperNoGRI[seccion][contentNoGRI.NContenido]) {
              ObjHelperNoGRI[seccion][contentNoGRI.NContenido] = [];
            }
            ObjHelperNoGRI[seccion][contentNoGRI.NContenido].push(contentNoGRI);

            if (!ObjHelperIndiceNOGRI[tema]) {
              ObjHelperIndiceNOGRI[tema] = [];
            }

            ObjHelperIndiceNOGRI[tema].push(contentNoGRI);
          }
        });
      });
    }
  }

  for (var keyObjhelper in ObjHelper) {
    if (Object.hasOwnProperty.call(ObjHelper, keyObjhelper)) {
      let seccion = [];
      const element = ObjHelper[keyObjhelper];
      for (var titulo in element) {
        if (Object.hasOwnProperty.call(element, titulo)) {
          let sub = [];
          let normas = [];

          const element2 = element[titulo].sort((a, b) => {
            return a.factorSort - b.factorSort;
          });

          element2.forEach((eleTitle, indexElement) => {
            let subtitulo = eleTitle.NContenido;
            let norma = eleTitle.NContenido;
            let estandar = "";

            if (
              (eleTitle.NContenido.indexOf("-") === -1 ||
                eleTitle.NContenido.indexOf("-") > 15) &&
              eleTitle.NContenido.indexOf(".") === -1
            ) {
              estandar = eleTitle.NContenido;
              subtitulo = "Contenido 3-3: Gestión de temas materiales";
            }

            if (
              eleTitle.isSectorial &&
              eleTitle.NContenido.slice(-2) == ".1"
            ) {
              subtitulo =
                "Contenido 3-3: Gestión de temas materiales, " +
                eleTitle.NContenido;
            }

            let textXML = "";

            var el = document.createElement("html");
            el.innerHTML = eleTitle.NTexto;

            textXML += htmlToXML(el.childNodes[1]);

            if (eleTitle.texto && eleTitle.texto.length > 0) {
              eleTitle.texto.forEach((text) => {
                var el2 = document.createElement("html");
                el2.innerHTML = text;
                textXML += htmlToXML(el2.childNodes[1]);
              });
            }

            sub.push({
              ESTANDAR: estandar,
              SUBTITULO: `${subtitulo}${
                (eleTitle.contenido && eleTitle.contenido.length) > 0
                  ? ", " + eleTitle.contenido.join(", ")
                  : ""
              }`,
              rawXML: textXML,
            });
            
            if (eleTitle.NContenido.indexOf(":") > -1) {
              normas.push(
                eleTitle.NContenido.substring(
                  0,
                  eleTitle.NContenido.indexOf(":")
                )
              );
            }
            if (norma.indexOf(".") > -1) {
              normas.push(norma);
            }

            if (eleTitle.contenido && eleTitle.contenido.length > 0) {
              normas.push(eleTitle.contenido.join(", "));
            }
          });
          seccion.push({
            TITULO: titulo,
            NORMAS: normas.join(", "),
            SUB: sub,
          });
        }
      }
      data[keyObjhelper] = seccion;
    }
  }

  for (var keyObjHelperNoGRI in ObjHelperNoGRI) {
  
    if (Object.hasOwnProperty.call(ObjHelperNoGRI, keyObjHelperNoGRI)) {
     
      let seccion = [];
     
      const element = ObjHelperNoGRI[keyObjHelperNoGRI];
      
      for (var titleNoGRI in element) {
        
        if (Object.hasOwnProperty.call(element, titleNoGRI)) {
          const element2 = element[titleNoGRI];
          let sub = [];
          element2.forEach((element3) => {
            sub.push({
              TEXTO: `${element3.NTexto}`,
            });
          });
          seccion.push({
            TITULO: titleNoGRI.trim(),
            SUB: sub,
          });
        }
      }
      data[keyObjHelperNoGRI] = seccion;
    }
  }

  let indice = [];

  for (var keyObjhelperIndice in ObjHelperIndice) {
    if (Object.hasOwnProperty.call(ObjHelperIndice, keyObjhelperIndice)) {
      let sub = [];
      let isG = true;
      let temaSectorial = "";
      let clave = "";
      let titulo = "";
      let sortIndex = 0;

      if (keyObjhelperIndice.indexOf("Sector") > -1) {
        sortIndex = 999;
        ObjHelperIndice[keyObjhelperIndice].forEach((element2) => {
          element2.sortindex2 = element2.NContenido.match(/\.\d+/)
            ? element2.NContenido.match(/\.\d+/)[0].substring(1)
            : 999;
        });
      } else {
        sortIndex = keyObjhelperIndice.match(/\d+/)
          ? keyObjhelperIndice.match(/\d+/)[0]
          : 999;
        ObjHelperIndice[keyObjhelperIndice].forEach((element2) => {
          element2.sortindex2 = element2.NContenido.match(/-\d+/)
            ? element2.NContenido.match(/-\d+/)[0].substring(1)
            : 0;
        });
      }

      let element = ObjHelperIndice[keyObjhelperIndice].sort((a, b) => {
        return a.sortindex2 - b.sortindex2;
      });

      element.forEach((element2, index) => {
     
        if (
          keyObjhelperIndice.indexOf("GRI 2:") > -1 ||
          keyObjhelperIndice.indexOf("GRI 3:") > -1
        ) {
          isG = false;
          titulo = keyObjhelperIndice;
          sub.push({
            TEMA: index === 0 ? keyObjhelperIndice : "",
            CONTENIDO:`${element2.NContenido}`,
            RELACIONADO:`${element2.contenido || ""}`,
          });
        } else if (keyObjhelperIndice.indexOf("Sector") > -1) {
          isG = false;
          titulo = keyObjhelperIndice;
          let tema = "";

          if (
            !element[index - 1] ||
            element2.name !== element[index - 1].name
          ) {
            tema =
              element2.NContenido.substring(0, element2.NContenido.length - 2) +
              "\n" +
              element2.name.charAt(0).toUpperCase() +
              element2.name.slice(1).toLowerCase();
          }
          sub.push({
            TEMA: tema,
            CONTENIDO: ``,
            RELACIONADO: `${element2.NContenido || ""}`,
          });
        } else {
          titulo = keyObjhelperIndice
            .substring(keyObjhelperIndice.indexOf(":") + 1)
            .replace(/\d+/g, "");

          if (
            (element2.NContenido.indexOf("-") === -1 ||
              element2.NContenido.indexOf("-") > 15) &&
            element2.NContenido.indexOf(".") === -1
          ) {
            clave = element2.NContenido;
            if (element2.contenido?.length > 0) {
              temaSectorial = element2.contenido.join(", ");
            }
          } else {
            sub.push({
              TEMA: clave,
              CONTENIDO: `${element2.NContenido}`,
              RELACIONADO: `${element2.contenido || ""}`,
            });
            clave = "";
          }
        }
      });

      indice.push({
        isG,
        temaSectorial,
        TITULO: titulo,
        sortIndex: sortIndex,
        SUB: sub,
      });
    }
  }

  for (var keyObjhelperIndiceNoGRI in ObjHelperIndiceNOGRI) {
    if (
      Object.hasOwnProperty.call(ObjHelperIndiceNOGRI, keyObjhelperIndiceNoGRI)
    ) {
      const element = ObjHelperIndiceNOGRI[keyObjhelperIndiceNoGRI];

      let isG = true;
      let clave = keyObjhelperIndiceNoGRI;
      let temaSectorial = "";
      let sortIndex = 9999;
     
      element.forEach((element2, index) => {
        let sub = [];
       
        clave = element2.NContenido;
        clave.trim();
        
        sub.push({
          TEMA: "NO GRI: " + clave,
          CONTENIDO: `${element2.NIndicador}`,
          RELACIONADO: "",
        });
        indice.push({
          isG,
          temaSectorial,
          sortIndex: sortIndex,
          TITULO: "NO GRI: " + clave,
          SUB: sub,
        });
      });
    }
  }

  let indiceOrdenado = indice.sort((a, b) => {
    return a.sortIndex - b.sortIndex;
  });

  data.INDICE = indiceOrdenado;
  loadFile(
    `${window.location.origin}/app/assets/templates/template${imagen}.docx`,
    function (error, content) {
      if (error) {
        toast.error("Ha ocurrido un error al generar el documento, refresque la página y vuelva a intentarlo");
        setShowLoader(false);
        setShowModalDisclaimer(false);
        throw error;
      }

  
      try {
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          parser: parser,
        });
        doc.setData(data);
      
    
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        toast.error("Ha ocurrido un error al generar el documento, refresque la página y vuelva a intentarlo");
        setShowLoader(false);
        setShowModalDisclaimer(false);
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        // function replaceErrors(key, value) {
        //   if (value instanceof Error) {
        //     return Object.getOwnPropertyNames(value).reduce(function (
        //       error,
        //       key
        //     ) {
        //       error[key] = value[key];
        //       return error;
        //     },
        //     {});
        //   }
        //   return value;
        // }

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation;
            })
            .join("\n");
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error;
      }

      var out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }); //Output the document using Data-URI
      saveAs(out, "plantilla de reporte gri.docx");
      setShowLoader(false);
      setShowModalDisclaimer(false);
    }
  );
};

function htmlToXML(node) {
  let xml = ``;

  let counter3 = 0;

  node.childNodes.forEach((childNode) => {
    switch (childNode.tagName) {
      case "OL":
        childNode.childNodes.forEach((childNode2) => {
          switch (childNode2.tagName) {
            case "LI":
              childNode2.childNodes.forEach((childNode3) => {
                if (
                  childNode3.nodeType === 3 &&
                  childNode3.textContent.trim()
                ) {
                  xml += `
<w:p>
  <w:pPr>
    <w:pStyle w:val="Texto"/>
    <w:ind w:left="1500" w:hanging="400" />
  </w:pPr>
  <w:r>
    <w:t>${letters[counter3]})    ${childNode3.textContent}</w:t>
  </w:r>
</w:p>
`;
                  counter3 = counter3 + 1;
                } else {
                  if (childNode3.tagName === "OL") {
                    let counter4 = 0;
                    childNode3.childNodes.forEach((childNode4) => {
                      if (childNode4.tagName === "LI") {
                        childNode4.childNodes.forEach((childNode5) => {
                          if (
                            childNode5.nodeType === 3 &&
                            childNode5.textContent.trim()
                          ) {
                            xml += `
                            <w:p>
                            <w:pPr>
                              <w:pStyle w:val="Texto"/>
                              <w:ind w:left="1900" w:hanging="400" />
                            </w:pPr>
                            <w:r>
                              <w:t>${roman[counter4]})    ${childNode5.textContent}</w:t>
                            </w:r>
                          </w:p>
                            `;
                            counter4 = counter4 + 1;
                          }
                        });
                      }
                    });

                    counter2 = counter2 + 1;
                  }
                }
              });

              break;

            default:
              break;
          }
        });

        break;
      case "UL":
        childNode.childNodes.forEach((childNode2) => {
          switch (childNode2.tagName) {
            case "LI":
              childNode2.childNodes.forEach((childNode3) => {
                if (
                  childNode3.nodeType === 3 &&
                  childNode3.textContent.trim()
                ) {
                  xml += `
<w:p>
  <w:pPr>
    <w:pStyle w:val="UL1"/>
  </w:pPr>
  <w:r>
    <w:t>${childNode3.textContent}</w:t>
  </w:r>
</w:p>
`;
                } else {
                  if (childNode3.tagName === "UL") {
                    childNode3.childNodes.forEach((childNode4) => {
                      if (childNode4.tagName === "LI") {
                        childNode4.childNodes.forEach((childNode5) => {
                          if (
                            childNode5.nodeType === 3 &&
                            childNode5.textContent.trim()
                          ) {
                            xml += `
<w:p>
  <w:pPr>
    <w:pStyle w:val="UL2"/>
  </w:pPr>
  <w:r>
    <w:t>${childNode5.textContent}</w:t>
  </w:r>
</w:p>
                            `;
                          }
                        });
                      }
                    });
                  }
                }
              });
              break;
            default:
              break;
          }
        });
        break;
      case "TABLE":
        let col = 1;

        let tableXML = "";

        let tbody = childNode.children[0].children;
        for (let index = 0; index < tbody.length; index++) {
          const tr = tbody[index];
          if (col < tr.children.length) {
            col = tr.children.length;
          }
        }

        let width = 4500 / col;

        tableXML += `<w:tbl>
        <w:tblPr>
          <w:tblStyle w:val="TableGrid"/>
          <w:tblW w:w="4500" w:type="pct"/>
          <w:jc w:val="start"/>
          <w:tblInd w:w="1000" w:type="dxa"/>
        </w:tblPr>
        <w:jc w:val="start"/>
        <w:tblGrid>
        `;

        for (let indexTable = 0; indexTable < col; indexTable++) {
          tableXML += `
          <w:gridCol w:w="${width}"/>`;
        }

        tableXML += `</w:tblGrid>`;

        for (let indexTable = 0; indexTable < tbody.length; indexTable++) {
          const tr = tbody[indexTable];

          tableXML += `
          <w:tr>`;

          for (
            let indexTable2 = 0;
            indexTable2 < tr.children.length;
            indexTable2++
          ) {
            const td = tr.children[indexTable2];

            tableXML += `
            <w:tc>
            <w:tcPr>
            <w:tcW w:w="${width}" w:type="dxa"/>
            </w:tcPr>
            <w:p>
            <w:r>
            <w:t>${td.textContent || ""}</w:t>
            </w:r>
            </w:p>
            </w:tc>`;
          }

          tableXML += `
          </w:tr>`;
        }

        tableXML += `
        </w:tbl>`;

        xml += tableXML;

        break;

      default:
        if (childNode.nodeType === 3 || childNode.tagName === "P") {
          xml += `
<w:p>
  <w:pPr>
    <w:pStyle w:val="Texto"/>
  </w:pPr>
  <w:r>
    <w:t>${childNode.textContent}</w:t>
  </w:r>
</w:p>`;
        }
        break;
    }
  });

  counter = counter + 1;
  return xml;
}

let letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

let roman = [
  "i",
  "ii",
  "iii",
  "iv",
  "v",
  "vi",
  "vii",
  "viii",
  "ix",
  "x",
  "xi",
  "xii",
  "xiii",
  "xiv",
  "xv",
  "xvi",
  "xvii",
  "xviii",
  "xix",
  "xx",
  "xxi",
  "xxii",
  "xxiii",
  "xxiv",
  "xxv",
];
