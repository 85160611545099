import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TiWarningOutline } from "react-icons/ti";
import { ErrorMessage } from "@hookform/error-message";
import { PostTemaNoGRI } from "../services/updateTemas.services";
import { toast, Toaster } from "react-hot-toast";
import { AuthServices } from "../../../services/Auth.services";
import { FaEdit } from "react-icons/fa";
import { cloneDeep } from "lodash";
import { IconContext } from "react-icons";
import { baseURL } from "../../../services/baseURL";
import axios from "axios";

export const UpdateTemasNoGRI = ({value, setTemaNoGRI}) => {
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const NTipoSector = ["Social", "Ambiental", "0", "Económico"];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      NTipo: "",
      NIndicador: "",
      NTema: "",
      NEstado: "",
      id: "",
    },
  });

  async function update() {
    const res = await PostTemaNoGRI();
    return res.status === 200
      ? await axios
          .get(`${baseURL}/getadicional`)
          .then((res) => {
            let data = res.data;
            let objets = { social: [], ambiental: [], economico: [] };
            let equivs = {
              Social: "social",
              Ambiental: "ambiental",
              Económico: "economico",
            };

            NTipoSector.forEach((el) => {
              let test = [];
              const dataValueNTipo = cloneDeep(data);
              let renderNTipo = dataValueNTipo.filter(
                (key) => key.NTipo === el
              );
              let uniqueValueNTema = [
                ...new Set(renderNTipo.map((a) => a.NTema)),
              ];
              uniqueValueNTema.forEach((el2) => {
                let z = renderNTipo.filter((val) => val.NTema === el2);
                test.push({ topic: el2, content: z });
              });
              objets[equivs[el]] = test;
            });
            toast.success("Los Cambios se han guardado exitosamente")
            setTemaNoGRI(objets);
          }).catch((err) => {
            toast.success("Los Cambios se han guardado exitosamente");
          })
      : null;
  }

  function updateTemaNoGRI() {
    value.content.forEach((element) => {
      setUpdateData(element);
      reset({ ...element });
    });
  }

  useEffect(() => {
    AuthServices.axiosInterceptors();
    updateTemaNoGRI();
  }, []);

  function openModal(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowModalUpdate(true);
  }

  function closeModal(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowModalUpdate(false);
  }

  const FormOnSubmit = (data) => {
    update(data);
    PostTemaNoGRI(data);
    setTimeout(() => setShowModalUpdate(false), 2500);
  };

  return (
    <>
          <IconContext.Provider value={{ color: "grey", size: "25px" }}>
            <FaEdit
              onClick={openModal}
              className="cursor-pointer absolute  pl-1 left-2 top-1.5"
              title="Editar Tema"
            />
          </IconContext.Provider>
          {showModalUpdate ? (
            <>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="fixed inset-0 w-full h-full bg-black opacity-40"> </div>
              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative  max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="border-0 modal-update-NoGRI rounded-lg bg-white  relative flex flex-col w-full outline-none focus:outline-none">
                    <div className="flex justify-end p-2 border-b border-solid border-gray-300 rounded-t ">
                      <button
                        className="bg-transparent border-0 text-black float-right"
                        onClick={closeModal}
                      >
                        <span className="text-black opacity-7 h-8 w-8 text-xl block bg-zinc-200 py-0 rounded-full">
                          x
                        </span>
                      </button>
                    </div>
                    <div className="relative  flex-auto">
                      <form
                        onSubmit={handleSubmit(FormOnSubmit)}
                        className="rounded px-8  w-full"
                      >
                        <label
                          htmlFor="id"
                          className="block text-sm font-semibold py-2.5  text-start secondary-title"
                        >
                          ID del tema
                        </label>
                        <input
                          defaultValue={updateData.id}
                          type="number"
                          required
                          name="id"
                          disabled
                          className={`block w-full px-3 py-2   bg-white  bg-input bg-disable  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                            errors.id &&
                            "focus:border-red-500 focus:ring-red-500 border-red-500"
                          }`}
                          {...register("id", {
                            minLength: {
                              value: 1,
                              message:
                                "ID del tema debe tener al menos 1 caracteres",
                            },
                            pattern: true,
                          })}
                          placeholder="ID del tema"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="id"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <div className="flex justify-start py-1 gap-0.5">
                                      {" "}
                                      <TiWarningOutline className="text-red-500" />
                                      <small
                                        className="text-xs text-start text-red-500 message"
                                        key={type}
                                      >
                                        {message}
                                      </small>
                                    </div>
                                  )
                                )
                              : null;
                          }}
                        />

                        <label
                          htmlFor="NTipo"
                          className="block text-sm font-semibold py-2.5  text-start secondary-title"
                        >
                          Tipo de Estándar
                        </label>
                        <input
                          defaultValue={updateData.NTipo}
                          disabled
                          type="text"
                          required
                          name="NTipo"
                          className={`block w-full px-3 py-2 bg-disable   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                            errors.NTipo &&
                            "focus:border-red-500 focus:ring-red-500 border-red-500"
                          }`}
                          {...register("NTipo", {
                            value: /^[A-Za-z0-9\s]+$/g,
                            message:
                              "tipo estándar debe tener al menos 3 caracteres",

                            pattern: true,
                          })}
                          placeholder="Tipo Estándar"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="NTipo"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <div className="flex justify-start py-1 gap-0.5">
                                      {" "}
                                      <TiWarningOutline className="text-red-500" />
                                      <small
                                        className="text-xs text-start text-red-500 message"
                                        key={type}
                                      >
                                        {message}
                                      </small>
                                    </div>
                                  )
                                )
                              : null;
                          }}
                        />

                        <label
                          htmlFor="NIndicador"
                          className="block text-sm font-semibold py-2.5  text-start secondary-title"
                        >
                          Descripción del tema
                        </label>
                        <input
                          defaultValue={updateData.NIndicador}
                          type="text"
                          required
                          name="NIndicador"
                          className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                            errors.NIndicador &&
                            "focus:border-red-500 focus:ring-red-500 border-red-500"
                          }`}
                          {...register("NIndicador", {
                            minLength: {
                              value: /[a-zA-Z ]{2,254}/,
                              message:
                                "Descripción del tema debe tener al menos 3 caracteres",
                            },
                            pattern: true,
                          })}
                          placeholder="Descripción del tema"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="NIndicador"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <div className="flex justify-start py-1 gap-0.5">
                                      {" "}
                                      <TiWarningOutline className="text-red-500" />
                                      <small
                                        className="text-xs text-start text-red-500 message"
                                        key={type}
                                      >
                                        {message}
                                      </small>
                                    </div>
                                  )
                                )
                              : null;
                          }}
                        />

                        {/** */}
                        <label
                          htmlFor="NTema"
                          className="block text-sm font-semibold py-2.5  text-start secondary-title"
                        >
                          Título del tema
                        </label>
                        <input
                          defaultValue={updateData.NTema}
                          type="text"
                          required
                          name="NTema"
                          className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                            errors.NTema &&
                            "focus:border-red-500 focus:ring-red-500 border-red-500"
                          }`}
                          {...register("NTema", {
                            minLength: {
                              value: /^[A-Za-z0-9\s]+$/g,
                              message:
                                "Título del tema debe tener al menos 3 caracteres",
                            },
                            pattern: true,
                          })}
                          placeholder="Nombre del tema a medir"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="NTema"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <div className="flex justify-start py-1 gap-0.5">
                                      {" "}
                                      <TiWarningOutline className="text-red-500" />
                                      <small
                                        className="text-xs text-start text-red-500 message"
                                        key={type}
                                      >
                                        {message}
                                      </small>
                                    </div>
                                  )
                                )
                              : null;
                          }}
                        />

                        {/** */}
                        <label
                          htmlFor="NEstado"
                          className="block text-sm font-semibold py-2.5  text-start secondary-title"
                        >
                          Activado o Desactivado
                        </label>
                        <label class="relative inline-flex items-center mb-5 cursor-pointer">
                          <input
                            defaultValue={updateData.NEstado}
                            name="NEstado"
                            type="checkbox"
                            className={`sr-only peer block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                              errors.NEstado &&
                              "focus:border-red-500 focus:ring-red-500 border-red-500"
                            }`}
                            {...register("NEstado", {
                              minLength: {
                                value: 3,
                                message:
                                  "El estado debe tener al menos 3 caracteres",
                              },
                              pattern: true,
                            })}
                            placeholder="Estado"
                          />
                          <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                        <ErrorMessage
                          errors={errors}
                          name="NEstado"
                          render={({ messages }) => {
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <div className="flex justify-start py-1 gap-0.5">
                                      {" "}
                                      <TiWarningOutline className="text-red-500" />
                                      <small
                                        className="text-xs text-start text-red-500 message"
                                        key={type}
                                      >
                                        {message}
                                      </small>
                                    </div>
                                  )
                                )
                              : null;
                          }}
                        />

                        <div class="btn-register px-7  tracking-wide  text-white font-semibold focus:bg-color-btn transition-colors duration-200 transform">
                          {" "}
                          <button type="submit" class="boxshadow">
                            Guardar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                </div>
              </div>
              <Toaster position="top-right" />
            </>
          ) : null}
        
      
    </>
  );
};