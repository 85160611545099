import axios from "axios";
import { baseURL } from "../../../services/baseURL";
import { toast } from "react-hot-toast";

export const PostTemaNoGRI = async (data) => {
  try {
    const response = await axios({
      url: `${baseURL}/editaradicional`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const GetValueUser = async () => {
  return await axios({
    url: `${baseURL}/getcompaniaactual`,
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.data);
};

export const GetTemasNoGRI = async () => {
  return await axios({
    url: `${baseURL}/getadicional`,
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.data);
};

export const TipoImgGRI = (data) => {
  axios
    .post(`${baseURL}/editartipo`, data)
    .then((res) => {
      toast.success("Los Cambios se han guardado exitosamente");
      sessionStorage.setItem("mostrarModal", "no");
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
