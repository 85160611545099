import axios from "axios";
import { baseURLAuth } from "./baseURL";


function setToken(token) {
  localStorage.setItem(`${baseURLAuth}/token`, token);
}

function getToken() {
  return localStorage.getItem(`${baseURLAuth}/token`);
}

function deleteToken() {
  return localStorage.removeItem(`${baseURLAuth}/token`);
}

function getCurrentUser() {
  return localStorage.getItem(`${baseURLAuth}/token`);
}

/*set the AUTH token resquet */

function axiosInterceptors() {
  axios.interceptors.request.use(function (config) {
    const token = getToken();

    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
  });
}

export const AuthServices = {
  setToken,
  getToken,
  deleteToken,
  axiosInterceptors,
  getCurrentUser,
};
