import React, { useState } from "react";
import { Footer } from "../../layouts/Footer";
import Navbar from "../../components/common/navbar";
import { ModalExencion } from "../TemplateG/component/ModalExencion";
import { redirect } from "react-router";

export const HomePage = () => {
  const [showModalExencion, setShowModalExencion] = useState(false);

  return (
    <>
      <Navbar />
      <main className="container-home">
        <section className="background-image">
          <div>
            {" "}
            <p className="text-white text-xl font-bold">
              Bienvenido a la plataforma para la generación de plantillas de
              reporte sostenible bajo los estándares GRI
            </p>
          </div>
        </section>
        <ModalExencion
          showModalExencion={showModalExencion}
          setShowModalExencion={setShowModalExencion}
        />
        <section className="options-pages">
          <div className="content-options">
            <div
              className="materials transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-white  duration-300"
              onClick={() => setShowModalExencion(true)}
            >
              <img
                src="https://www.globalreporting.org/media/0ilpmiow/standardscovers.jpg?height=310&width=919&mode=crop"
                className="object-cover object-center"
              ></img>
              <div className="description">
                <h3 className="text-black font-bold text-xl text-title">
                  Recursos
                </h3>
                <p className="text-black font-normal text-sm text-parrafo">
                  En esta sección podrás acceder a material audiovisual que te
                  apoyará con el proceso de reporte bajo los estándares GRI.
                </p>
              </div>
            </div>
            <div className="templates-GRI transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110  hover:bg-white  duration-300">
              <img
                src="https://www.globalreporting.org/media/yx4jqp42/gri-universal-desk.jpg"
                className="object-cover object-top"
              ></img>
              <div className="description ">
                <h3 className="text-black font-bold text-xl text-title">
                  Generador de plantillas
                </h3>
                <a className="text-black font-normal text-sm text-parrafo" href="/app/Plantillas-GRI">
                  En esta sección podrás generar plantillas que te permitirán
                  elaborar reportes que cumplan los estándares GRI.
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};
