import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HeaderGeneric } from "../../components/common/HeaderGeneric";
import { Footer } from "../../layouts/Footer";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { IconContext } from "react-icons";
import axios from "axios";
import { AuthServices } from "../../services/Auth.services";
import { ErrorMessage } from "@hookform/error-message";
import { TiWarningOutline } from "react-icons/ti";
import { baseURLAuth } from "../../services/baseURL";
import { toast, Toaster } from "react-hot-toast";

export const LoginPage = () => {
  const [openEye, setOpenEye] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setOpenEye(!openEye);
  };

  let rerouteToGRIEmail = () =>
    window.open(
      "https://r1.dotdigital-pages.com/p/4J5-SLH/gri-email-communication-sign-up-form"
    );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const onFormSubmit = (data) => {
   
      axios
        .post(`${baseURLAuth}/token`, data)
        .then((res) => {
          /*GET token from response */
          const token = ("token", res.data.token);

          /*set JWT token to local */
          AuthServices.setToken(token);
          /*set token to axios commom header */
          AuthServices.axiosInterceptors(token);
          /*redirect user to page */
          const time = setTimeout(() => navigate("/app/home"), 1000);
          toast.success("Ha iniciado sesión exitosamente.")
          return () => clearTimeout(time);
        })
        .catch((e) => {
          toast.error("Usuario o contraseña incorrectos.");
        })
    
    
  };

  return (
    <>
      <HeaderGeneric />
      <main className="items-center justify-center mb-9">
        <div className="container mx-auto  w-80 p-2 drop-shadow-xl">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="bg-white max-h-full w-95 p-8 flex-col flex gap-0.5 rounded-lg">
              <h3 className="font-bold p-2 text-center primary-title">
                Iniciar Sesión
              </h3>
              <label
                htmlFor="username"
                className="block text-sm py-2.5  font-semibold text-start secondary-title"
              >
                Correo
              </label>

              <input
                type="text"
                name="username"
                autoComplete="username email"
                required
                placeholder="Correo"
                className={`block w-full px-4 py-2 bg-white border bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs ${
                  errors.password &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("username", {
                  required: "Éste campo es obligatorio.",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Ingrese un correo válido.",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="username"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <label
                htmlFor="password"
                className="block text-sm py-2.5  text-start font-semibold  secondary-title"
              >
                Contraseña
              </label>
              <div className="mb-2 relative">
                <input
                  autoComplete="current-password"
                  {...register("password", {
                    required: "Éste campo es obligatorio",
                    pattern: {
                      value:
                        /^(?=.*\d)([A-Za-z\d]|[^ ]){8,15}$/,
                      message:
                        "Min 8 Max 15 caracteres, una letra Min,May, un dígito,No espacios, 1 caracter especial.",
                    },
                  })}
                  type={openEye === false ? "password" : "text"}
                  required
                  className={`block w-full px-4 py-2 bg-white border bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs ${
                    errors.password &&
                    "focus:border-red-500 focus:ring-red-500 border-red-500"
                  }`}
                  placeholder="Contraseña"
                />
                <IconContext.Provider value={{ color: "grey", size: "17px" }}>
                  <div className="text-1xl absolute top-2 right-2">
                    {openEye === false ? (
                      <AiFillEye onClick={toggle} />
                    ) : (
                      <AiFillEyeInvisible onClick={toggle} />
                    )}
                  </div>
                </IconContext.Provider>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) => {
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <div className="flex justify-start py-1 gap-0.5">
                            {" "}
                            <TiWarningOutline className="text-red-500" />
                            <small
                              className="text-xs text-start text-red-500 message"
                              key={type}
                            >
                              {message}
                            </small>
                          </div>
                        ))
                      : null;
                  }}
                />
              </div>
              <p className="py-0.5 text-xs font-light text-start from-forget  text-gray-700">
                {" "}
                ¿Olvidaste tu contraseña?,{" "}
                <Link
                  to={"/app/forgotpassword"}
                  className="font-medium   from-register hover:underline"
                >
                  Recuperar contraseña.
                </Link>
              </p>
              <div className="mt-4 text-center">
                <button className="boxshadow px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-color-btn rounded-md hover:bg-color-btn focus:outline-none font-semibold focus:bg-color-btn">
                  Iniciar Sesión
                </button>
              </div>
              <p className="mt-4 text-xs font-light text-center from-forget  text-gray-700">
                {" "}
                ¿No tienes cuenta?,{" "}
                <Link
                  to={"/app/Register"}
                  className="font-medium text-purple-600  from-register hover:underline"
                >
                  Registrarse aquí.
                </Link>
              </p>
              <div className="pt-4">
                <small className="mt-8 text-xs font-light text-center from-forget  text-gray-700">
                  Mantén el contacto con el GRI. Si desea recibir comunicaciones
                  por correo electrónico de GRI, marque todas las casillas de
                  verificación del tipo de correos electrónicos que le interesan
                  haga click{" "}
                  <a
                    onClick={(e) => rerouteToGRIEmail(e)}
                    className="cursor-pointer secondary-title underline"
                  >
                    Aquí
                  </a>
                </small>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Toaster position="top-right" />
      <Footer />
    </>
  );
};
