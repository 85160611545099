import sector from "../../services/sector.json";
import country from "../../services/country.json";
import PhoneInput from "react-phone-number-input";
import { Footer } from "../../layouts/Footer";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ListSocios } from "../RegisterUser/components/selectSocio/ListSocio";
import { IconContext } from "react-icons";
import { useState, useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Toaster } from "react-hot-toast";
import { TiWarningOutline } from "react-icons/ti";
import { PostInviteUser } from "./services/inviteUser.services";
import { AuthServices } from "../../services/Auth.services";
import Navbar from "../../components/common/navbar";
import { GetValueUser } from "../TemplateG/services/updateTemas.services";
import { getGeoInfo } from "../RegisterUser/services/getsocio.services";

export const InviteUser = () => {
  const [socio, setSocio] = useState([]);

  function passData(socios) {
    setSocio(socios);
  }
  /*handle events*/
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({});

  async function getValueInviteUser() {
    const res = await GetValueUser();
    let defaultValues = res[0];
    reset({ ...defaultValues });
  }

  const [countryCode, setCountryCode] = useState("PE");

  async function getInfo() {
    const response = await getGeoInfo();
    let data = response.data;
    setCountryCode(data.country_calling_code);
  }

  useEffect(() => {
    getInfo();
    AuthServices.axiosInterceptors();
    getValueInviteUser();
  }, []);

  /*handle submit events*/
  const onFormSubmit = (data) => {
    PostInviteUser(data);
  };

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    });
  };

  return (
    <>
      <Navbar />
      <div className="grid  container-form">
        <h1 className="font-bold p-2 text-center primary-title">
          Invitar Usuario
        </h1>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="mt-6 grid form-elements"
        >
          <div className="mb-2 flex flex-col flex flex-column">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Correo
            </label>
            <div>
              <input
                type="text"
                name="email"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.email &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("email", {
                  required: "Éste campo es obligatorio.",
                  maxLength: {
                    value: 100,
                    message: "El correo debe tener como máximo 100 caracteres.",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Ingrese un correo válido.",
                  },
                })}
                placeholder="Correo"
              />
            </div>
            {errors.email && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.email.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NCompania"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Razón Social
            </label>
            <div>
              <input
                disabled
                type="text"
                name="NCompania"
                className={`block w-full bg-gray-200 px-3 py-2 mt-2  bg-white   placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NCompania &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NCompania", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "Razón social debe tener al menos 3 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Razón social"
              />
            </div>
            {errors.NCompania && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NCompania.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NSector"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Sector{" "}
            </label>

            <select
              disabled
              className={`disabled:opacity-100 block w-full px-3 py-2 mt-2  bg-white bg-gray-200 rounded  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NSector &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NSector", { required: "NSector es requerido" })}
            >
              <option className="text-sm" defaultValue="default">
                Seleccione sector
              </option>
              {sector.map((e, key) => {
                return (
                  <option key={key} className="text-sm" value={e.name}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>
            {errors.NSector && (
              <span className="text-xs text-start text-red-500">
                {errors.NSector.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <ListSocios passData={passData} />
            <label
              htmlFor="NSocio"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Socio implementador{" "}
            </label>

            <select
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NSocio &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NSocio", {
                required: "Socio implementador es requerido",
              })}
            >
              <option defaultValue="default">Seleccione socio</option>
              {Object.keys(socio).map((pais, index) => {
                return (
                  <optgroup key={index} label={pais}>
                    {renderOptions(socio[pais])}
                  </optgroup>
                );
              })}
            </select>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                <AiFillCaretDown />
              </div>
            </IconContext.Provider>
            {errors.NSocio && (
              <span className="text-xs text-start text-red-500">
                {errors.NSocio.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NTelefono"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Teléfono{" "}
            </label>
            <Controller
              name="NTelefono"
              control={control}
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NTelefono &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NTelefono", {
                message:
                  "Número de teléfono no puede ser inferior ó mayor a 9 dígitos",
                pattern: "/^[0-9]+$/i",
              })}
              rules={{ validate: (value) => isValidPhoneNumber(value) }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  international
                  defaultCountry={countryCode}
                  value={value}
                  onChange={onChange}
                  rules={{ required: true }}
                />
              )}
            />
            {errors["NTelefono"] && (
              <span className="text-xs text-start text-red-500">
                Número de teléfono es requerido
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NPais"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              País{" "}
            </label>
            <div className="relative">
              <select
                disabled
                className={`block w-full bg-gray-200 px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NPais &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NPais", { required: "País es requerido" })}
              >
                <option value="" className="bg-gray-200">
                  Seleccione país
                </option>
                {country.map((e, key) => {
                  return (
                    <option
                      key={key}
                      className="text-sm bg-gray-200"
                      value={e.name}
                    >
                      {e.name}
                    </option>
                  );
                })}
              </select>
              <IconContext.Provider value={{ color: "grey", size: "13px" }}>
                <div
                  style={{ marginTop: "-12%", marginLeft: "87%" }}
                  className="text-1xl relative"
                >
                  <AiFillCaretDown />
                </div>
              </IconContext.Provider>
            </div>
            {errors.NPais && (
              <span className="text-xs text-start text-red-500">
                {errors.NPais.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NWeb"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Página web{" "}
            </label>
            <div>
              <input
                disabled
                type="text"
                name="NWeb"
                className={`block w-full px-3 py-2 mt-2  bg-gray-200 rounded  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NWeb &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NWeb", {
                  minLength: {
                    value: 3,
                    message: "La página web debe tener al menos 3 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Página web"
              />
            </div>
            {errors.NWeb && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NWeb.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Nombre{" "}
            </label>
            <div>
              <input
                type="text"
                name="first_name"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.first_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("first_name", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El nombre debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El nombre debe tener como máximo 100 caracteres.",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Nombre"
              />
            </div>
            {errors.first_name && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.first_name.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="lastname"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Apellido{" "}
            </label>
            <div>
              <input
                type="text"
                name="last_name"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.last_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("last_name", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El apellido debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El apellido debe tener como máximo 100 caracteres.",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Apellido"
              />
            </div>
            {errors.last_name && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.last_name.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="cargo"
              className="block text-sm text-start font-semibold  secondary-title"
            >
              Cargo{" "}
            </label>
            <div>
              <input
                type="name"
                name="cargo"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.cargo &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("cargo", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El cargo debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El cargo debe tener como máximo 100 caracteres.",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Cargo"
              />
            </div>
            {errors.cargo && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.cargo.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NAncla"
              className="block text-sm text-start font-semibold  secondary-title"
            >
              {" "}
              Empresa ancla (opcional)
            </label>
            <div>
              <input
                type="text"
                name="NAncla"
                {...register("NAncla", {
                  minLength: {
                    value: 3,
                    message: "Empresa ancla debe tener al mennos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "Empresa ancla debe tener como máximo 100 caracteres.",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                className="block w-full px-3 py-2 mt-2  bg-white border bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs"
                placeholder="Empresa ancla"
              />
            </div>
            {errors.NAncla && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NAncla.message}
                </span>
              </div>
            )}
          </div>

          <div className="btn-register px-3 py-2 tracking-wide  text-white font-semibold focus:bg-color-btn transition-colors duration-200 transform">
            {" "}
            <button type="submit" value="Registrarse" className="boxshadow">
              Registrarse
            </button>
          </div>
        </form>
      </div>
      <Toaster position="top-right" />
      <Footer />
    </>
  );
};
