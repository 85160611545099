export const thematicContents = ["Social", "Ambiental", "0", "Económico"];
export const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
export const optionYear = {
  year: "numeric",
};
export const textDisclaimer = (
  <p class="text-black font-normal text-md text-parrafo">
    El formato digital de reporte basado en los Estándares GRI se construyó en
    el marco del Programa de Negocios Competitivos del GRI con el objetivo de
    ayudar a las organizaciones participantes a estructurar y facilitar la
    elaboración de sus reportes de sostenibilidad. Este formato contiene los
    Estándares GRI vigentes a la fecha{" "}
    <strong> {new Date().toLocaleDateString("es-pe", options)}</strong> que se
    realiza la descarga del documento. Si va hacer un reporte “De conformidad
    con los Estándares GRI” y en su formato de reporte incluyó Contenidos de un
    Estándar Sectorial recuerde que la organización informante está obligada a
    informar los contenidos de los Estándares Temáticos enumerados para los
    temas que haya determinado como materiales. Si algún contenido de los
    Estándares Temáticos indicados no es relevante para los impactos de la
    organización, no está obligada a incluirlo. <br />
    <br /> No obstante, la organización está obligada a enumerar estos
    contenidos en el índice de contenidos GRI e indicar "no procede" como el
    motivo para la omisión de la información de ese contenido. Adicionalmente se
    han incluido las Recomendaciones adicionales para el sector y la Información
    sectorial adicional, esto debido a ayudan a describer otra información que
    se ha identificado como relevante en relación a un tema para los informes de
    las organizaciones del sector en particular. <br />
    <br />
    La organización debería presenter información suficiente sobre sus impactos
    en relación a cada tema material para que los usuarios de la información
    puedan hacer evaluaciones y tomar decisiones informadas sobre la
    organización. Por esta razón, se anima a presentar información sobre estos
    contenidos y recomendaciones adicionales para el sector, aunque no es un
    requerimiento. Cuando la organización presenta información sectorial
    adicional, está obligada a indicarlo en el índice de contenidos GRI.
    <br />
    <br /> Ni la Junta Directiva de GRI, ni el GSSB, ni Stichting Global
    Reporting Initiative (GRI) pueden responsabilizarse de las consecuencias o
    los daños resultantes directa o indirectamente del uso de este formato de
    reporte por parte de terceros.
  </p>
);
export let ThematicContentsPlantillas = {
  social: [],
  ambiental: [],
  economico: [],
};
export let ThematicContentsNoGRI = {
  Social: "social",
  Ambiental: "ambiental",
  Económico: "economico",
};