import React from "react";
import linkedin from "../assets/icons/linkedin.svg";
import twitter from "../assets/icons/twitter.svg";
import medium from "../assets/icons/medium.svg";
import youtube from "../assets/icons/youtube.svg";
import SECO from "../assets/images/SECO.jpg";

export const Footer = () => {
  return (
    <>
      <div className="footer text-center h-170 p-4 mx-auto  px-2 sm:px-6 lg:px-8 leading-10">
       
         
        <div className="terms-privacy flex-wrap justify-center flex flex-row-reverse  items-center">
        <ul className="icon-link gap-3 text-start">
            <li className="linkedin-content">
              <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQH160vJudgyfQAAAYYVtB14xCrrjRqbe8MWRDpHZkYL93fiVlRXFNIbElMcQEy-7JV9fswfqCsccmtALDyptWEFQTRsZaGUBa2jAx6jSIikW04-8DSKFY2YA7gMk-qDKZyxvOo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fglobal-reporting-initiative-gri"  target="_blank" className="linkendin z-4 ">
              <img className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110" src={linkedin}></img>
              </a>
             
            </li>
            <li>
              <a href="https://twitter.com/GRI_Secretariat"  target="_blank" className="twitter">
                <img src={twitter} className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110"></img>
              </a>
            </li>
            <li>
              <a href="https://medium.com/@GlobalReportingInitiative"  target="_blank" className="Medium">
                <img src={medium} className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110"></img>
              </a>
            </li>
            <li>
              {" "}
              <a href="https://www.youtube.com/channel/UC0ETfBwgtVLYc8SHWaYjczg"  target="_blank" className="youtobe">
                <img src={youtube} className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110"></img>
              </a>
            </li>
          </ul>
        <div className="flex items-center justify-center flex-wrap gap-1 flex">
      
        <div className="flex-col flex">
        <img src="https://www.globalreporting.org/styles/assets/svg/GRI_Master_Logo-solo.svg" className="h-16 max-w-md w-auto" />
        <p className="text-color text-xs text-parrafo font-semibold">©2022 Global Reporting Initiative, all rights reserved </p>
          <p className="text-color text-xs text-parrafo font-semibold">Privacy policy | Copyright </p>
        </div>
        <div className="">
        <a href="https://www.eda.admin.ch/countries/colombia/es/home/cooperacion/temas/seco.html#:~:text=La%20Cooperaci%C3%B3n%20Econ%C3%B3mica%20y%20Desarrollo,los%20desaf%C3%ADos%20globales%20del%20agua." target="_blank" className="flex items-center justify-center gap-2 max-w-xs flex-col">
          <img src={SECO} className="h-16 max-w-md w-auto rounded"/>
            <p className="text-color text-xs text-parrafo font-semibold">Este formato digital se ha realizado con el apoyo de la Cooperación Económica y Desarrollo (SECO) </p>
            
          </a>
        </div>
        </div>
       
         
      
        </div>
      </div>
    </>
  );
};
