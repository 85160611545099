import React from "react";
import Navbar from "../../components/common/navbar";
import { Modal } from "./component/Modal";
// import { Footer } from "../../layouts/Footer";
import { NavTabs } from "./component/NavTabs";
export const TemplateG = () => {

  return (
    <>
      <Navbar />
      <div className="mt-8">
        <NavTabs />
      </div>
      <Modal/>
    </>
  );
};
