import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURLResetPassword } from "../../../services/baseURL";

export async function newPassword(data) {
  try {
    const response = await axios({
      url: `${baseURLResetPassword}/set-password`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    toast.success("Su contraseña ha sido modificada")
    
    return response;
  } catch (error) {
    toast.error("Su contraseña no ha sido modificada")
  }
}
