import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL } from "../../../services/baseURL";

export async function postUser(data) {
  try {
    const response = await axios({
      url: `${baseURL}/crearusuario`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    toast.success("Usuario registrado, se le enviará un correo con los accesos a la plataforma cuando su cuenta sea activada.");
    return response;
  } catch (error) {
    console.log(error);
  }
}
