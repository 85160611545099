import React from "react";
import { HeaderGeneric } from "../../components/common/HeaderGeneric";
import { Footer } from "../../layouts/Footer";
import { useForm } from "react-hook-form";
import { ResetPassword } from "./service/resetPassword.service";
import { ErrorMessage } from "@hookform/error-message";
import { TiWarningOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { Back } from "../../components/common/Back";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const onFormSubmit = (data) => {
    sessionStorage.setItem("email", data.email);
    ResetPassword(data);

    const time = setTimeout(() =>  navigate("/app/insertpassword"), 1000);
    return () => clearTimeout(time);
  };

  return (
    <>
      <HeaderGeneric />
      <main className="items-center justify-center my-24">
        <div className="container mx-auto p-2 w-80 p-1 shadow-2xl rounded-lg">
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="bg-white  max-h-full w-95 p-8">
              <div className="flex justify-center items-center">
                {window.location.href === "http://localhost:3000/" ? (
                  ""
                ) : (
                  <NavLink to={"/home"}>
                    <Back
                      className={"bracket"}
                      height={"25px"}
                      width={"25px"}
                      fill={"#1158a6"}
                    />
                  </NavLink>
                )}
                <h3 className="font-semibold p-2 text-center primary-title reset-password">
                  Recuperar contraseña
                </h3>
              </div>
              <label
                htmlFor="email"
                className={`block text-sm py-2.5  font-semibold text-start secondary-title ${
                  errors.email &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
              >
                Correo
              </label>
              <input
                type="text"
                name="email"
                required
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.email &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                placeholder="Correo"
                {...register("email", {
                  required: "Éste campo es obligatorio.",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Ingrese un correo válido.",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <div className="mt-5 text-center">
                <button
                  type="submit"
                  className="boxshadow px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-color-btn rounded-md hover:bg-color-btn focus:outline-none font-semibold focus:bg-color-btn"
                >
                  Recuperar
                </button>
              </div>
            </div>
          </form>
        </div>
        <Toaster position="top-right" />
      </main>
      <Footer />
    </>
  );
};
