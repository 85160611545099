import React from "react";
import { NavLink } from "react-router-dom";

export const NoFounds = () => {
  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-error">
            <span className="sr-only">Error</span>404
          </h2>
          <p className="text-2xl font-semibold md:text-3xl text-title">
            Lo sentimos, no logramos encontrar esta página.
          </p>
          <p className="mt-4 mb-8 dark:text-gray-400 text-parrafo">
            Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra
            página de{" "}
            <NavLink
              to={"/app/home"}
              rel="noopener noreferrer"
              className="font-semibold rounded  underline secondary-title"
            >
              Inicio
            </NavLink>
          </p>
        </div>
      </div>
    </section>
  );
};
