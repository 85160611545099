import React from "react";
import iconGRI from "../../assets/icons/LogoGRI.jpg";

export const HeaderGeneric = () => {
  return (
    <>
      <header className="flex p-5 justify-between items-center">
        <img src={iconGRI} className="logoGRI" alt="logoGRI"></img>
      </header>
    </>
  );
};
