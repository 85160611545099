import React, { useEffect, useState } from "react";
import { VscHistory, VscOpenPreview, VscPlay } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { AuthServices } from "../../../services/Auth.services";

export const BtnViewHistory = ({ history, contenidos, repeatHistory }) => {
  const [showModal, setShowModal] = useState(false);

  const [toggle, setToggle] = useState({});

  useEffect(() => {
    AuthServices.axiosInterceptors();
  }, []);

  const toggleTab = (index) => {
    toggle[index] = !toggle[index];

    setToggle({ ...toggle });
    console.log(toggle);
  };

  return (
    <>
      <div className="flex h-16 justify-center items-end mt-2 mx-2">
        <button
          type="button"
          className=" transform transition duration-500  hover:text-white btnViewPreview items-center p-2 text-btn-Previex flex   justify-center gap-1"
          onClick={(e) => {
            setShowModal(true);
          }}
        >
          <IconContext.Provider value={{ size: "20px" }}>
            <VscHistory />
          </IconContext.Provider>
          <h4>Ver Historial</h4>
        </button>
      </div>

      <>
        <div>
          {showModal ? (
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModal(false)}
              ></div>

              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex justify-center">
                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                      {history.length === 0 ? (
                        <h1>"No hay reportes en el historial"</h1>
                      ) : (
                        <></>
                      )}
                      {history.map((value, index) => (
                        <div key={index}>
                          <h4 className="text-sm  text-gray-800 rounded-sm flex items-center  justify-center py-5 titelAccordionHeader accordion-head h-9 titelAccordionHeader">
                            {index} - Reporte generado el {value.DFecha}{" "}
                            {toggle[index] ? "GG" : "FF"}
                            <div
                              className="mx-2"
                              onClick={() => toggleTab(index)}
                            >
                              <IconContext.Provider value={{ size: "20px" }}>
                                <VscOpenPreview />
                              </IconContext.Provider>
                            </div>
                            <div
                              className="mx-2"
                              onClick={() => {
                                setShowModal(false);
                                repeatHistory(value.NContenido);
                              }}
                            >
                              <IconContext.Provider value={{ size: "20px" }}>
                                <VscPlay />
                              </IconContext.Provider>
                            </div>
                          </h4>

                          {toggle[index] ? (
                            <ul className="leading-10 list-none">
                              {value.NContenido.map((ele, index2) =>
                                contenidos[ele] ? (
                                  <li
                                    key={index + "-" + index2}
                                    className="text-black font-normal text-sm text-parrafo py-1 "
                                  >
                                    {contenidos[ele]}
                                  </li>
                                ) : (
                                  <></>
                                )
                              )}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </>
    </>
  );
};
