import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { cloneDeep } from "lodash";
import { VscOpenPreview } from "react-icons/vsc";
import { generateDocument } from "../docxTemplater";
import { IconContext } from "react-icons";
import { Plantillas } from "../services/Plantilla";
import { AuthServices } from "../../../services/Auth.services";
import {
  NSecciones,
  options,
  optionYear,
  textDisclaimer,
} from "../services/globalVariables";
import { ReactComponent as Loader } from "../../../assets/images/Loader.svg";
import { TiWarningOutline } from "react-icons/ti";
import { FaCalendarCheck } from "react-icons/fa";
import { FaCalendarTimes } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { Toaster,toast } from "react-hot-toast";

export const BtnViewPreview = ({
  TemaNoGRI,
  Data,
  startDate,
  endDate,
  company,
  typeSector,
  messageSectorial,
  arraySectorial,
}) => {
  const [arrayChecked, setArrayChecked] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDesclaimer, setShowModalDisclaimer] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { register, handleSubmit, watch } = useForm({
    mode: "onChange",
  });
  let fecha1 = startDate?.toLocaleDateString("es-pe", options) || "";
  let fecha2 = endDate?.toLocaleDateString("es-pe", options) || "";
  let fecha3 = new Date()?.toLocaleDateString("es-pe", options) || "";
  let periodo = fecha2.slice(-4) || "";

  let isSectorial = true;
  let sector = company?.NSector;
  let tipo = company?.NTipo;

  if (typeSector === true) {
    if (sector?.length > 1) {
      isSectorial = false;
    } else {
      isSectorial = true;
    }
  }

  useEffect(() => {
    AuthServices.axiosInterceptors();
  }, []);

  const registrarPlantilla = () => {
    let arrayisChecked = [];
    let obj = {};
    for (const key in Data) {
      if (Object.hasOwnProperty.call(Data, key)) {
        const element = Data[key];

        element.forEach((element2) => {
          element2.content.forEach((element3) => {
            if (element3.isChecked) {
              arrayisChecked.push(element3);
            }
          });
        });
      }
    }

    for (const key in TemaNoGRI) {
      if (Object.hasOwnProperty.call(TemaNoGRI, key)) {
        const element = TemaNoGRI[key];

        element.forEach((element2) => {
          element2.content.forEach((element3) => {
            if (element3.isChecked) {
              element3.NContenido = element2.topic;
              element3.NTema = "Temas no GRI";
              element3.NTexto = element3.NIndicador;
              switch (element3.NTipo) {
                case "Económico":
                  element3.NSeccion = "5";
                  break;
                case "Ambiental":
                  element3.NSeccion = "6";
                  break;
                case "Social":
                  element3.NSeccion = "7";
                  break;

                default:
                  break;
              }
              arrayisChecked.push(element3);
            }
          });
        });
      }
    }

    arrayisChecked.forEach((item) => {
      if (!obj[item.NTema]) {
        obj[item.NTema] = [];
      }
      obj[item.NTema].push(item);
    });

    let array = [];

    for (let NTemas in obj) {
      array.push({ topic: NTemas, content: obj[NTemas] });
    }

    array.sort(
      (a, b) =>
        (a.topic.match(/\d+/) ? a.topic.match(/\d+/)[0] : 999) -
        (b.topic.match(/\d+/) ? b.topic.match(/\d+/)[0] : 999)
    );
    setArrayChecked(array);
  };

  function filterID() {
    let IDNContenido = [];

    for (const key in arrayChecked) {
      if (Object.hasOwnProperty.call(arrayChecked, key)) {
        const element = arrayChecked[key];
        element.content.forEach((el) => {
          IDNContenido.push(el.id);
        });
      }
    }

    let data = {
      NContenido: IDNContenido,
    };

    Plantillas(data);
  }

  const Btnpreview = () => {
    registrarPlantilla();
  };

  function btnDownloadPlantilla() {
    setShowLoader(true);
    filterID();

      generateDocument(
        Data,
        periodo,
        fecha1,
        fecha2,
        fecha3,
        company.NCompania,
        tipo === "1"
          ? `${company.NCompania} ha elaborado el informe conforme a los Estándares GRI para el periodo comprendido entre ${fecha1} y ${fecha2} del periodo objeto del informe`
          : `${company.NCompania} ha presentado la información citada en este índice de contenidos GRI para el periodo comprendido entre ${fecha1} y ${fecha2} del periodo objeto del informe utilizando como referencia los Estándares GRI.`,
        company.NImagen,
        messageSectorial === true ? sector : "",
        isSectorial,
        arraySectorial,
        TemaNoGRI,
        setShowLoader,
        setShowModalDisclaimer,
        toast
      );
   

    
  }

  return (
    <>
      <div className="flex h-16 justify-center items-end mt-2 mx-2">
        <button
          type="button"
          className=" transform transition duration-500  hover:text-white btnViewPreview items-center p-2 text-btn-Previex flex   justify-center gap-1"
          onClick={(e) => {
            Btnpreview(e);
            setShowModal(true);
          }}
        >
          <IconContext.Provider value={{ size: "20px" }}>
            <VscOpenPreview />
          </IconContext.Provider>
          <h4>Vista Previa Plantilla</h4>
        </button>
      </div>

      <>
        <div>
          {showModal ? (
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowModal(false)}
              ></div>

              <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                  <div className="mt-3 sm:flex justify-center">
                    {Object.entries(arrayChecked).length ? (
                      <div className="mt-2 text-center sm:ml-4 sm:text-left">
                        <div className="flex flex-wrap justify-center gap-1">
                          {fecha1.length > 0 && fecha2.length > 0 ? (
                            <p className="completed flex items-center gap-1  text-xs text-green-500">
                              <IconContext.Provider
                                value={{ color: "", size: "18px" }}
                              >
                                {" "}
                                <FaCalendarCheck />
                              </IconContext.Provider>
                              ¡Los campos han sido validados exitosamente!
                            </p>
                          ) : (
                            <p className="error flex items-center gap-1  text-xs text-red-500">
                              <IconContext.Provider
                                value={{ color: "", size: "18px" }}
                              >
                                {" "}
                                <FaCalendarTimes />
                              </IconContext.Provider>
                              Por favor, seleccione fecha inicial, y fecha final
                            </p>
                          )}
                        </div>
                        <div className="flex items-center h-16 justify-center">
                          <button
                            disabled={
                              fecha1.length > 0 && fecha2.length > 0
                                ? false
                                : true
                            }
                            type="button"
                            className=" transform transition duration-500  hover:text-white btnViewPreview p-2 text-btn-Previex flex   justify-center gap-1 disabled:opacity-50"
                            onClick={() => {
                              setShowModal(false);
                              setShowModalDisclaimer(true);
                            }}
                          >
                            <h4>Descargar Plantilla</h4>
                          </button>
                        </div>
                        <div>
                          <>
                            <div>
                              {arrayChecked?.map((key, value) => (
                                <div key={value}>
                                  <h4 className="text-sm  text-gray-800 rounded-sm flex items-center  justify-center py-5 titelAccordionHeader accordion-head h-9 titelAccordionHeader">
                                    {key.topic}
                                  </h4>
                                  <ul className="leading-10 list-none">
                                    {key.content.map((ele) => (
                                      <li
                                        key={ele.id}
                                        className="text-black font-normal text-sm text-parrafo py-1 "
                                      >
                                        {ele.NContenido} {ele.NIndicador}
                                        <br />
                                        {typeSector === true
                                          ? ele.contenido
                                          : ""}
                                        {typeSector === true ? ele.texto : ""}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1.5">
                        <TiWarningOutline className="text-red-500" />
                        Por favor debe seleccionar al menos un contenido por
                        tema.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </>
      {showModalDesclaimer ? (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-full px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3 sm:flex justify-center"></div>

              <div className="mt-2 text-center sm:ml-4 sm:text-left">
                <div>
                  <>
                    <section className="max-w-screen-sm mx-auto">
                      <form onSubmit={handleSubmit()}>
                        <div class="">
                          <div class="">
                            <h3 className="pb-8 text-black font-bold text-center text-xl text-title">
                              Exención de responsabilidad
                            </h3>
                            {textDisclaimer}
                          </div>

                          <div className="flex  flex-end items-center pt-2 gap-2">
                            <input
                              onChange={() =>
                                setCheckbox((checked) => !checked)
                              }
                              type="checkbox"
                              value="1"
                              name="isValid"
                              className="rounded cursor-pointer"
                              {...register("isValid")}
                              required
                            />
                            <label className="text-black font-normal text-sm text-parrafo">
                              Aceptar excepción de responsabilidad
                            </label>
                          </div>
                        </div>
                        <div className="flex justify-center flex-wrap pt-8 gap-2">
                          <button
                            disabled={!watch("isValid")}
                            loading={false}
                            type="button"
                            className=" transform transition duration-500 hover:text-white cursor-pointer boxshadow   btn-generarReporte"
                            onClick={() => btnDownloadPlantilla()}
                          >
                            {!showLoader ? (
                              "Aceptar"
                            ) : (
                              <Loader className="spinner" />
                            )}{" "}
                          </button>

                          <button
                            onClick={() => setShowModalDisclaimer(false)}
                            type="button"
                            className=" transform transition duration-500  text-zinc-500 font-normal text-sm text-parrafo btn-cancel-reporte  border-solid border-2 ring-slate-400 boxshadow cursor-pointer"
                          >
                            Cancelar
                          </button>
                        </div>
                      </form>
                    </section>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
       <Toaster position="top-right" />
    </>
  );
};
