import { React, useState, useEffect, useRef } from "react";
import { baseURL } from "../../../services/baseURL";
import axios from "axios";
import { ModalTemasNoGRI } from "./ModalTemasNoGRI";
import { Disclosure } from "@headlessui/react";
import { BtnViewPreview } from "./BtnViewPreview";
import { BtnViewHistory } from "./BtnViewHistory";
import { cloneDeep } from "lodash";
import { AuthServices } from "../../../services/Auth.services";
import { UpdateTemasNoGRI } from "./UpdateTemasNoGRI";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImCalendar } from "react-icons/im";
import { IconContext } from "react-icons";
import { Modal } from "./Modal";
import {
  GetTemasNoGRI,
  GetValueUser,
  TipoImgGRI,
} from "../services/updateTemas.services";
import {
  thematicContents,
  ThematicContentsNoGRI,
  ThematicContentsPlantillas,
} from "../services/globalVariables";
import es from "date-fns/locale/es";
import { Footer } from "../../../layouts/Footer.js";
registerLocale("es", es);

export const NavTabs = () => {
  const [toggleState, setToggleState] = useState(1);
  const [TemaNoGRI, setTemaNoGRI] = useState([]);
  const [objectGRI, setObjectGRI] = useState([]);
  const [Data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messageSectorial, setMessageSectorial] = useState(false);
  const [company, setCompany] = useState("");
  const [arraySectorial, setArraySectorial] = useState([]);
  const [typeSector, setTypeSector] = useState(false);
  const [history, setHistory] = useState([]);
  const [contenidosObject, setContenidosObject] = useState({});
  const buttonRefs = useRef([]);
  const openedRef = useRef(null);

  function openModal(objets, companyInfo) {
    if (!window.sessionStorage.getItem("mostrarModal")) {
      setIsOpen(true);
    } else {
      filterValue(sessionStorage.getItem("tipoReporte"), objets, companyInfo);
    }
  }

  const filterValue = (value, dataFilter = Data, companyInfo = company) => {
    const newData = cloneDeep(dataFilter);

    let arrayIsSectorial = [];
    newData.todos?.forEach((el) => {
      el.content.forEach((ele) => {
        if (value === "1") {
          if (ele.NGri === "1") {
            ele.isChecked = true;
            ele.isDisable = true;
            setTypeSector(value === "1");
          } else {
            ele.isChecked = false;
            ele.isDisable = false;
          }

          if (
            ele.isSectorial === "1" &&
            ele.NSectorTema === companyInfo.NSector
          ) {
            setMessageSectorial(value === "1");

            if (ele.NRelacionado === "0") {
              arraySectorial.push(ele);
            }
            if (!arrayIsSectorial[ele.NRelacionado]) {
              arrayIsSectorial[ele.NRelacionado] = [];
            }
            arrayIsSectorial[ele.NRelacionado].push({
              sectorial: ele.isSectorial,
              contenido: ele.NContenido,
              relacionado: ele.NRelacionado,
              texto: ele.NTexto,
            });
          }
        }

        if (value === "2") {
          if (ele.NRef === "1") {
            ele.isChecked = true;
            ele.isDisable = true;
            setMessageSectorial(value === "1");
            setTypeSector(value === "1");
          } else {
            ele.isChecked = false;
            ele.isDisable = false;
          }
        }
      });
    });

    newData.todos?.forEach((c) => {
      c.content.forEach((d) => {
        if (!d.contenido) {
          d.contenido = [];
          d.texto = [];
        }
      });
    });

    arrayIsSectorial?.forEach((a) => {
      newData.todos?.forEach((c) => {
        c.content.forEach((d) => {
          a.forEach((e) => {
            if (e.relacionado === d.id) {
              d.contenido.push(e.contenido);
              d.texto.push(e.texto);
            }
          });
        });
      });
    });

    newData.social?.forEach((c) => {
      c.content.forEach((d) => {
        if (!d.contenido) {
          d.contenido = [];
          d.texto = [];
        }
      });
    });

    arrayIsSectorial?.forEach((a) => {
      newData.social?.forEach((c) => {
        c.content.forEach((d) => {
          a.forEach((e) => {
            if (e.relacionado === d.id) {
              d.contenido.push(e.contenido);
              d.texto.push(e.texto);
            }
          });
        });
      });
    });

    newData.ambiental?.forEach((c) => {
      c.content.forEach((d) => {
        if (!d.contenido) {
          d.contenido = [];
          d.texto = [];
        }
      });
    });

    arrayIsSectorial?.forEach((a) => {
      newData.ambiental?.forEach((c) => {
        c.content.forEach((d) => {
          a.forEach((e) => {
            if (e.relacionado === d.id) {
              d.contenido.push(e.contenido);
              d.texto.push(e.texto);
            }
          });
        });
      });
    });

    newData.economico?.forEach((c) => {
      c.content.forEach((d) => {
        if (!d.contenido) {
          d.contenido = [];
          d.texto = [];
        }
      });
    });

    arrayIsSectorial?.forEach((a) => {
      newData.economico?.forEach((c) => {
        c.content.forEach((d) => {
          a.forEach((e) => {
            if (e.relacionado === d.id) {
              d.contenido.push(e.contenido);
              d.texto.push(e.texto);
            }
          });
        });
      });
    });

    setArraySectorial(arraySectorial);
    setData(newData);
  };

  const handleChangeContent = (e) => {
    const { name, checked, value } = e.target;

    const newData = cloneDeep(Data);

    newData.todos.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NContenido === name) {
          ele.isChecked = checked;
          return;
        }

        if (value === "1") {
          if (ele.NGri === "1") {
            ele.isChecked = checked;
          }
        }

        if (value === "2") {
          if (ele.NRef === "1") {
            ele.isChecked = checked;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeContent2 = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    newData.todos.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name && ele.isDisable === false) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const clickRecent = (index) => {
    const clickedButton = buttonRefs.current[index];
    if (clickedButton === openedRef.current) {
      openedRef.current = null;
      return;
    }
    if (Boolean(openedRef.current?.getAttribute("data-value"))) {
      openedRef.current?.click();
    }
    openedRef.current = clickedButton;
  };

  const repeatHistory = (ids) => {
    const newData = cloneDeep(Data);

    Object.entries(newData.todos).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });

    Object.entries(newData.economico).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });
    Object.entries(newData.ambiental).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });
    Object.entries(newData.social).forEach(([key, value]) => {
      value.content.forEach((a, index) => {
        if (ids.indexOf(a.id) > -1) {
          a.isChecked = true;
        } else {
          a.isChecked = false;
        }
      });
    });

    setData(newData);
  };

  const handleChangeEconomyNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.economico).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeEconomyNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const handleChangeAmbientalNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.ambiental).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeSocialNConten = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);

    Object.entries(newData.social).forEach(([key, value]) => {
      let checkestandar = false;

      value.content.forEach((a, index) => {
        if (a.NContenido === name) {
          a.isChecked = checked;
          if (value.topic === a.NTema) {
            value.content.forEach((el) => {
              if (el.isChecked && el.NContenido.indexOf("Estándar") === -1) {
                checkestandar = true;
              }
            });
            value.content[0].isChecked = checkestandar;
          }
        }
      });
    });
    setData(newData);
  };

  const handleChangeAmbientalesNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  const handleChangeSocialNTema = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newData = cloneDeep(Data);
    newData.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setData(newData);
  };

  async function plantillas(companyInfo) {
    await axios
      .get(`${baseURL}/getcontenido`)
      .then((res) => {
        let data = res.data;

        console.log(res.data);

        let contenidoObject = {};

        data.forEach((el) => {
          contenidoObject[el.id] = el.NContenido;
        });

        setContenidosObject(contenidoObject);

        let thematicContentGlobal = {
          Social: "social",
          Ambiental: "ambiental",
          Económico: "economico",
          0: "todos",
        };

        thematicContents.forEach((el) => {
          let aux = [];
          const dataValueNTipo = cloneDeep(data);
          let renderNTipo = dataValueNTipo.filter((key) => key.NTipo === el);
          let uniqueValueNTema = [...new Set(renderNTipo.map((a) => a.NTema))];

          uniqueValueNTema.forEach((el2) => {
            let z = renderNTipo.filter((val) => val.NTema === el2);
            aux.push({ topic: el2, content: z });
          });
          ThematicContentsPlantillas[thematicContentGlobal[el]] = aux;
        });

        for (const key in ThematicContentsPlantillas) {
          if (Object.hasOwnProperty.call(ThematicContentsPlantillas, key)) {
            const element = ThematicContentsPlantillas[key];
            for (let index = 0; index < element.length; index++) {
              const element2 = element[index];
              element[index].content = element2.content.sort((a, b) => {
                return (
                  (a.NContenido.match(/-\d+/)
                    ? a.NContenido.match(/-\d+/)[0].substring(1)
                    : 0) -
                  (b.NContenido.match(/-\d+/)
                    ? b.NContenido.match(/-\d+/)[0].substring(1)
                    : 0)
                );
              });
            }

            ThematicContentsPlantillas[key] = element.sort((a, b) => {
              let factora =
                a.content[0].NContenido.indexOf("Tema") > -1 ? 1000 : 1;
              let factorb =
                b.content[0].NContenido.indexOf("Tema") > -1 ? 1000 : 1;
              return (
                a.topic.match(/\d+/)[0] / factora -
                b.topic.match(/\d+/)[0] / factorb
              );
            });
          }
        }
        setData(ThematicContentsPlantillas);
        setLoading(false);
        openModal(ThematicContentsPlantillas, companyInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const TemasNOGRI = (temaNOGRI) => {
    const objectsTemasNoGRI = { social: [], ambiental: [], economico: [] };
    thematicContents.forEach((el) => {
      let test = [];

      let renderNTipo = temaNOGRI.filter((key) => key.NTipo === el);
      let uniqueValueNTema = [...new Set(renderNTipo.map((a) => a.NTema))];

      uniqueValueNTema.forEach((el2) => {
        let z = renderNTipo.filter((val) => val.NTema === el2);
        test.push({ topic: el2, content: z });
      });
      objectsTemasNoGRI[ThematicContentsNoGRI[el]] = test;
      setTemaNoGRI(objectsTemasNoGRI);
    });
  };

  function updateCompany(data) {
    company.NImagen = data.NImagen;
    company.NTipo = data.NTipo;
    setCompany(company);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([GetValueUser(), GetTemasNoGRI()])
      .then(([valueCompany, temasNOGRI]) => {
        setCompany(valueCompany[0]);
        plantillas(valueCompany[0]);
        TemasNOGRI(temasNOGRI);
        setObjectGRI(temasNOGRI);
        getHistory();
      })
      .catch((er) => console.log(er));
    filterValue();
    AuthServices.axiosInterceptors();
  }, []);

  async function getHistory() {
    await axios
      .get(`${baseURL}/gethistorial`)
      .then((res) => {
        setHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleChangeEconomyNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const TemasNoGRI = cloneDeep(TemaNoGRI);
    TemasNoGRI.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(TemasNoGRI);
  };

  const handleChangeEconomyNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const TemasNoGRI = cloneDeep(TemaNoGRI);
    TemasNoGRI.economico.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(TemasNoGRI);
  };

  const handleChangeAmbientalNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeAmbientalNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.ambiental.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });

    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeSocialNTemaNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NTema === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const handleChangeSocialNContenNoGRI = (e) => {
    const { name, checked } = e.target;
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const newDataIndicadorNoGRI = cloneDeep(TemaNoGRI);
    newDataIndicadorNoGRI.social.forEach((el) => {
      el.content.forEach((ele) => {
        if (ele.NIndicador === name) {
          ele.isChecked = checked;
        }
      });
    });
    setTemaNoGRI(newDataIndicadorNoGRI);
  };

  const onChangeCalendar = (Date) => {
    const [start, end] = Date;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div className="">
        <div className="max-w-screen-lg mx-auto p-7 flex flex-wrap   justify-between container-date">
          <div>
            <label htmlFor="" className="labelcalendar">
              {" "}
              Fecha de Reporte
            </label>

            <div className="flex gap-2 pt-1.5">
              <div className="relative">
                <DatePicker
                  selected={startDate}
                  onChange={onChangeCalendar}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  locale={"es"}
                  shouldCloseOnSelect={startDate && !endDate}
                  placeholderText="Fecha Inicial, Fecha Final"
                  className="custom-pickers"
                  dateFormat={"dd/MM/yyyy"}
                />
                <IconContext.Provider value={{ color: "grey", size: "19px" }}>
                  <div className="text-1xl absolute  icon-calendar-start-date">
                    <ImCalendar />
                  </div>
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <div>
            <BtnViewHistory
              history={history}
              contenidos={contenidosObject}
              repeatHistory={repeatHistory}
            />
          </div>
          <div>
            <BtnViewPreview
              Data={Data}
              startDate={startDate}
              endDate={endDate}
              TemaNoGRI={TemaNoGRI}
              typesector={typeSector}
              messageSectorial={messageSectorial}
              arraySectorial={arraySectorial}
              company={company}
            />
          </div>
        </div>

        <section className="max-w-screen-lg contain-tab flex mx-auto p-7 gap-14">
          <button
            className={
              toggleState === 1
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(1)}
          >
            Estándares Universales
          </button>
          <button
            className={
              toggleState === 2
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(2)}
          >
            Estándares Económicos
          </button>
          <button
            className={
              toggleState === 3
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(3)}
          >
            Estándares Ambientales
          </button>
          <button
            className={
              toggleState === 4
                ? "tabs active-tabs"
                : "tabs transform transition duration-500 hover:text-white"
            }
            onClick={() => toggleTab(4)}
          >
            Estándares Sociales
          </button>
        </section>
        <div className="flex justify-center animate-pulse">
          {loading && (
            <div>
              <div className="flex items-center justify-center space-x-2 h-40 mt-16 animate-bounce">
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title  rounded-full">
                  G
                </div>
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title rounded-full">
                  R
                </div>
                <div className="w-14 h-14  bg-sky-600 flex justify-center items-center text-white md:font-bold text-title rounded-full">
                  I
                </div>
              </div>
            </div>
          )}
        </div>

        {!loading && (
          <div className="container-accordion">
            <div className="content-tabs">
              <div
                className={
                  toggleState === 1 ? "content active-content" : "content"
                }
              >
                <div>
                  {" "}
                  <div className="sectorial">
                    {messageSectorial === true
                      ? `Estándar sectorial GRI aplicable: ${company.NSector}`
                      : ""}
                  </div>
                </div>
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.todos?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div
                              className={`mb-2 rounded-md border ${
                                el.content[0].isSectorial === "1"
                                  ? "sector"
                                  : ""
                              }`}
                            >
                              <Disclosure.Button
                                as="div"
                                className="transform cursor-pointer transition duration-500  btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div
                                  className={`flex text-start gap-2  accordion items-center accordion${index}`}
                                >
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeContent2}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="transform cursor-pointer transition duration-500  accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black transform cursor-pointer transition duration-500`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    id={ele.id}
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    disabled={ele.isDisable}
                                    onChange={(e) => {
                                      handleChangeContent(e);
                                    }}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 2 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.economico?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeEconomyNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7  gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    id={ele.id}
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    value={ele.isTrue}
                                    onChange={handleChangeEconomyNConten}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className=" btn-accordion flex justify-between px-5 flex-row-reverse  py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>

                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.economico.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.economico?.map(
                                        (value, index) => (
                                          <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                            <Disclosure key={value.topic}>
                                              <>
                                                <div className="">
                                                  <div
                                                    as="div"
                                                    className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                  >
                                                    <div className="flex text-start gap-2 accordion items-center">
                                                      <UpdateTemasNoGRI
                                                        TemaNoGRI={TemaNoGRI}
                                                        setTemaNoGRI={
                                                          setTemaNoGRI
                                                        }
                                                        value={value}
                                                        id={index}
                                                      />
                                                      <input
                                                        type="checkbox"
                                                        onChange={
                                                          handleChangeEconomyNTemaNoGRI
                                                        }
                                                        className="rounded border-gray-300 cursor-pointer"
                                                        name={value.topic}
                                                        value={value.topic}
                                                      />
                                                      <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                        {value.topic}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {value.content?.map((b) => (
                                                    <div
                                                      key={b.id}
                                                      className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                    >
                                                      <input
                                                        name={b.NIndicador}
                                                        checked={b.isChecked}
                                                        onChange={
                                                          handleChangeEconomyNContenNoGRI
                                                        }
                                                        type="checkbox"
                                                        className="rounded border-gray-300 cursor-pointer"
                                                      />
                                                      {b.NIndicador}
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                            </Disclosure>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 3 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.ambiental?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="  btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeAmbientalesNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content.map((ele) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    onChange={handleChangeAmbientalNConten}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />

                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className="btn-accordion flex justify-between px-5 flex-row-reverse   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.ambiental.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.ambiental?.map(
                                        (value, index) => (
                                          <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                            <Disclosure key={value.topic}>
                                              <>
                                                <div className="">
                                                  <div
                                                    as="div"
                                                    className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                  >
                                                    <div className="flex text-start gap-2 accordion items-center">
                                                      <UpdateTemasNoGRI
                                                        TemaNoGRI={TemaNoGRI}
                                                        setTemaNoGRI={
                                                          setTemaNoGRI
                                                        }
                                                        value={value}
                                                        id={index}
                                                      />
                                                      <input
                                                        type="checkbox"
                                                        onChange={
                                                          handleChangeAmbientalNTemaNoGRI
                                                        }
                                                        className="rounded border-gray-300 cursor-pointer"
                                                        name={value.topic}
                                                        value={value.topic}
                                                      />
                                                      <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                        {value.topic}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {value.content?.map((b) => (
                                                    <div
                                                      key={b.id}
                                                      className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                    >
                                                      <input
                                                        name={b.NIndicador}
                                                        checked={b.isChecked}
                                                        onChange={
                                                          handleChangeAmbientalNContenNoGRI
                                                        }
                                                        type="checkbox"
                                                        className="rounded border-gray-300 cursor-pointer"
                                                      />
                                                      {b.NIndicador}
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                            </Disclosure>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>

            <div className="content-tabs">
              <div
                className={
                  toggleState === 4 ? "content active-content" : "content"
                }
              >
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    {Data.social?.map((el, index) => (
                      <Disclosure key={el.topic}>
                        {({ open }) => (
                          <>
                            <div className="mb-2 rounded-md border">
                              <Disclosure.Button
                                as="div"
                                className="btn-accordion flex justify-between px-5   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                              >
                                <div className="flex text-start gap-2 accordion items-center">
                                  <input
                                    type="checkbox"
                                    onClick={handleChangeSocialNTema}
                                    name={el.topic}
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                    {el.topic}
                                  </p>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "rotate-180 transform" : ""
                                  } h-10 w-10 black cursor-pointer`}
                                >
                                  <button
                                    data-value={open}
                                    ref={(ref) => {
                                      buttonRefs.current[index] = ref;
                                    }}
                                    onClick={() => clickRecent(index)}
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </Disclosure.Button>
                              {el.content?.map((ele, index) => (
                                <Disclosure.Panel
                                  key={ele.id}
                                  className="px-5 leading-7 gap-2 border-b-2 border-neutral-100 flex items-center"
                                >
                                  <input
                                    name={ele.NContenido}
                                    checked={ele.isChecked || false}
                                    onChange={handleChangeSocialNConten}
                                    type="checkbox"
                                    className="rounded border-gray-300 cursor-pointer"
                                  />
                                  <p>
                                    Sección: {ele.NSeccion}.
                                    <br />
                                    {ele.NContenido}.
                                    <br />
                                    {typeSector === true ? ele.contenido : ""}
                                  </p>
                                </Disclosure.Panel>
                              ))}
                            </div>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>

                {/********************************************** */}
                <div className="">
                  <div className=" bg-white rounded-2xl px-3.5 pb-3.5 pt-3.5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <div className="mb-2 rounded-md border">
                            <ModalTemasNoGRI setTemaNoGRI={setTemaNoGRI} />
                            <Disclosure.Button
                              as="div"
                              className="btn-accordion flex justify-between px-5 flex-row-reverse   py-2 text-sm font-medium text-left accordion-head  rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`${
                                  open ? "rotate-180 transform" : ""
                                } h-10 w-10 black cursor-pointer`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-5 leading-7 gap-2 border-b-2 border-neutral-100">
                              <div className="max-w-4xl">
                                {TemaNoGRI.social.length ? (
                                  <div>
                                    <div className="content-tabs">
                                      {TemaNoGRI.social?.map((value, index) => (
                                        <div className=" bg-white rounded-2xl px-3.5 pb-0.5 pt-2">
                                          <Disclosure key={value.topic}>
                                            <>
                                              <div className="">
                                                <div
                                                  as="div"
                                                  className=" relative  btn-accordion flex justify-between pl-11 pr-5   py-2 text-sm font-medium text-left   rounded-lg hover:accordion-head focus:outline-none focus-visible:ring focus-visible:accordion-head focus-visible:ring-opacity-75"
                                                >
                                                  <div className="flex text-start gap-2 accordion items-center">
                                                    <UpdateTemasNoGRI
                                                      TemaNoGRI={TemaNoGRI}
                                                      setTemaNoGRI={
                                                        setTemaNoGRI
                                                      }
                                                      value={value}
                                                      id={index}
                                                    />
                                                    <input
                                                      type="checkbox"
                                                      onChange={
                                                        handleChangeSocialNTemaNoGRI
                                                      }
                                                      className="rounded border-gray-300 cursor-pointer"
                                                      name={value.topic}
                                                      value={value.topic}
                                                    />
                                                    <p className="accordion__title titelAccordionHeader text-start flex items-center text-base">
                                                      {value.topic}
                                                    </p>
                                                  </div>
                                                </div>
                                                {value.content?.map((b) => (
                                                  <div
                                                    key={b.id}
                                                    className="pl-11 flex leading-7 items-center gap-2  border-neutral-100"
                                                  >
                                                    <input
                                                      name={b.NIndicador}
                                                      checked={b.isChecked}
                                                      onChange={
                                                        handleChangeSocialNContenNoGRI
                                                      }
                                                      type="checkbox"
                                                      className="rounded border-gray-300 cursor-pointer"
                                                    />
                                                    {b.NIndicador}
                                                  </div>
                                                ))}
                                              </div>
                                            </>
                                          </Disclosure>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex p-5 ml-1.5">
                                    No hay nuevo Tema No GRI.
                                  </div>
                                )}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        filterValue={filterValue}
        setCompany={setCompany}
        company={company}
        updateCompany={updateCompany}
      />
      <Footer />
    </>
  );
};