import React from "react";
import { HomePage } from "../pages/Home/HomePage";
import { TemplateG } from "../pages/TemplateG/TemplateGPage";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { LoginPage } from "../pages/Login/LoginPage";
import { RegisterUserPage } from "../pages/RegisterUser/RegisterUserPage";
import { ForgotPasswordPage } from "../pages/ForgotPassword/ForgotPasswordPage";
import { InsertNewPassword } from "../pages/NewPassword/InsertNewPassword";
import { EditUser } from "../pages/EditProfile/EditProfilePage";
import { InviteUser } from "../pages/InviteUser/inviteUser";
import { EditCompany } from "../pages/EditCompany/EditCompany";
import { NoFounds } from "./NoFounds";

export const RouterConfig = () => {
  return (
    <>
      <Routes>
        <Route path="/app/Register" element={<RegisterUserPage />} />
        <Route path="/app/" element={<LoginPage />} />
        <Route path="/app/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/app/insertpassword" element={<InsertNewPassword />} />

        <Route
          path="/app/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/app/edituser"
          element={
            <PrivateRoute>
              <EditUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/app/inviteuser"
          element={
            <PrivateRoute>
              <InviteUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/app/editcompany"
          element={
            <PrivateRoute>
              <EditCompany />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/Materiales"
          element={
            <PrivateRoute>
              <Navbar/>
              <MaterialsPage />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/app/Plantillas-GRI"
          element={
            <PrivateRoute>
              <TemplateG />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NoFounds />}></Route>
      </Routes>
    </>
  );
};
