import React, { useEffect, useState } from "react";
import Navbar from "../../components/common/navbar";
import { Footer } from "../../layouts/Footer";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { TiWarningOutline } from "react-icons/ti";
import { Toaster } from "react-hot-toast";
import { dataProfile } from "./services/dataProfile";
import { baseURL } from "../../services/baseURL";
import axios from "axios";
import { AuthServices } from "../../services/Auth.services";

export const EditUser = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: { user_email: "", cargo: "", first_name: "", last_name: "" },
  });

  const getValueUser = async () => {
    const data = await axios
      .post(`${baseURL}/loggedinuser`)
      .then((res) => {
        let defaultValue = res.data.data;
        reset({ ...defaultValue });
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  useEffect(() => {
    AuthServices.axiosInterceptors();
    getValueUser();
  }, []);

  const onFormSubmit = (data) => {
    dataProfile(data);
  };

  return (
    <>
      <Navbar />
      <main className="items-center justify-center min-h-min pt-8 pb-8 flex">
        <div className="container mx-auto p-2 w-80 p-1 shadow-2xl rounded-lg">
          <form onSubmit={handleSubmit(onFormSubmit)} className="flex justify-center items-center">
            <div className="h-full  w-72 flex flex-col p-8  rounded-lg">
              <h3 className="font-bold p-2 text-center primary-title">
                Editar perfil
              </h3>
              <label
                htmlFor="user_email"
                className="block text-sm py-2.5  font-semibold text-start secondary-title"
              >
                Correo
              </label>
              <input
                type="text"
                name="user_email"
                placeholder="Correo"
                required
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.email &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("user_email", {
                  required: "Éste campo es obligatorio.",
                   maxLength: {
                    value: 100,
                    message: "El correo debe tener como máximo 100 caracteres.",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Ingrese un correo válido.",
                  },
                })}
              />

              <ErrorMessage
                errors={errors}
                name="user_email"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <label
                htmlFor="first_name"
                className="block text-sm font-semibold py-2.5  text-start secondary-title"
              >
                Nombre
              </label>
              <input
                type="text"
                required
                name="first_name"
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.first_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("first_name", {
                  minLength: {
                    value: 3,
                    message: "El nombre debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El nombre debe tener como máximo 100 caracteres.",
                  },
                  pattern: true,
                })}
                placeholder="Nombre"
              />
              <ErrorMessage
                errors={errors}
                name="first_name"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <label
                htmlFor="last_name"
                className="block text-sm font-semibold py-2.5  text-start secondary-title"
              >
                Apellido
              </label>
              <input
                type="text"
                required
                name="last_name"
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.last_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("last_name", {
                  minLength: {
                    value: 3,
                    message: "El apellido debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El apellido debe tener como máximo 100 caracteres.",
                  },
                  pattern: true,
                })}
                placeholder="Apellido"
              />
              <ErrorMessage
                errors={errors}
                name="last_name"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <label
                htmlFor="cargo"
                className="block text-sm font-semibold py-2.5  text-start secondary-title"
              >
                Cargo
              </label>
              <input
                type="text"
                required
                name="cargo"
                className={`block w-full px-3 py-2   bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.cargo &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("cargo", {
                  minLength: {
                    value: 3,
                    message: "El cargo debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El cargo debe tener como máximo 100 caracteres.",
                  },
                  pattern: true,
                })}
                placeholder="Cargo"
              />
              <ErrorMessage
                errors={errors}
                name="cargo"
                render={({ messages }) => {
                  return messages
                    ? Object.entries(messages).map(([type, message]) => (
                        <div className="flex justify-start py-1 gap-0.5">
                          {" "}
                          <TiWarningOutline className="text-red-500" />
                          <small
                            className="text-xs text-start text-red-500 message"
                            key={type}
                          >
                            {message}
                          </small>
                        </div>
                      ))
                    : null;
                }}
              />

              <div className="mt-5 text-center">
                <button
                  type="submit"
                  className="boxshadow px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-color-btn rounded-md hover:bg-color-btn focus:outline-none font-semibold focus:bg-color-btn w-3/4"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
        <Toaster position="top-right" />
      </main>
      <Footer />
    </>
  );
};