import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURLResetPassword } from "../../../services/baseURL";

export async function ResetPassword(data) {

  try {
    const response = await axios({
      url: `${baseURLResetPassword}/reset-password`,
      data,
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
  
    toast.success("Se ha enviado un código a su correo")
    return response;
  } catch (error) {
    toast.error("Dirección de correo , no registrado")
  }
}
