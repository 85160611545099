import axios from "axios";
import { baseURL } from "../../../services/baseURL";

export async function getSocios() {
  try {
    const response = await axios({
      url: `${baseURL}/getsocio`,
      method: "GET",
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getGeoInfo() {
  try {
    const response = await axios({
      url: "https://ipapi.co/json/",
      method: "GET",
    });
    return response;
  } catch (err) {
    console.log(err);
  }
}
