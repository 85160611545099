import { useState, useEffect } from "react";
import { getSocios } from "../../services/getsocio.services";

export const ListSocios = (props) => {
  const [socios, setSocios] = useState([]);

  useEffect(() => {
    async function loadSocio() {
      const response = await getSocios();
      let objCountry = {};
      response.data.map((item) => {
        if (!objCountry.hasOwnProperty(item.pais)) {
          objCountry[item.pais] = [];
        }

        objCountry[item.pais].push({
          name: item.name,
          id: item.id,
        });
      });
      setSocios(objCountry);
    }
    loadSocio();
  }, []);

  return <>{props.passData(socios)}</>;
};
