import sector from "../../services/sector.json";
import country from "../../services/country.json";
import PhoneInput from "react-phone-number-input";
import { HeaderGeneric } from "../../components/common/HeaderGeneric";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../layouts/Footer";
import { Controller, useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { postUser } from "./services/createaccount.services";
import { IconContext } from "react-icons";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Back } from "../../components/common/Back";
import { TiWarningOutline } from "react-icons/ti";
import { getSocios, getGeoInfo } from "./services/getsocio.services";
import { NavLink } from "react-router-dom";

export const RegisterUserPage = () => {
  const [socio, setSocio] = useState([]);

  const [countryCode, setCountryCode] = useState("PE");

  /*handle events*/
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    control,
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();

  async function getInfo() {
    const response = await getGeoInfo();
    let data = response.data;
    setCountryCode(data.country_calling_code);
  }

  async function loadSocio() {
    const response = await getSocios();
    let objCountry = {};
    Object.entries(response.data).forEach(([pais, value]) => {
      if (!objCountry.hasOwnProperty(value.pais)) {
        objCountry[value.pais] = [];
      }

      objCountry[value.pais].push({
        name: value.name,
        id: value.id,
      });
    });
    setSocio(objCountry);
  }

  useEffect(() => {
    getInfo();
    loadSocio();
  }, []);

  /*handle submit events*/

  const onFormSubmit = (data) => {
    postUser(data);

    const time = setTimeout(() => navigate("/app"), 1000);
        return () => clearTimeout(time);
  };

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      );
    });
  };

  return (
    <>
      <HeaderGeneric />
      <div className="grid  container-form">
      <div className="flex justify-center items-center gap-2">
      {window.location.href==="http://localhost:3000/" ? '' : <NavLink to={"/app/home"}>
        <Back
        className={'bracket'}
        height={'25px'}
        width={'25px'}
        fill={'#1158a6'}
        />
       
        </NavLink> }
        <h1 className="font-bold p-2 text-center primary-title">Registrarse</h1>
        </div>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="mt-6 grid form-elements"
        >
          <div className="mb-2 flex flex-col flex flex-column">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Correo
            </label>
            <div>
              <input
                type="text"
                name="email"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.email &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("email", {
                  required: "Éste campo es obligatorio.",
                  maxLength: {
                    value: 100,
                    message: "El correo debe tener como máximo 100 caracteres",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Ingrese un correo válido.",
                  },
                })}
                placeholder="Correo"
              />
            </div>
            {errors.email && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.email.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NCompania"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Razón Social
            </label>
            <div>
              <input
                type="text"
                name="NCompania"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NCompania &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NCompania", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "Razón social debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "Razón social debe tener como máximo 100 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Razón social"
              />
            </div>
            {errors.NCompania && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NCompania.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NSector"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Sector{" "}
            </label>

            <select
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NSector &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NSector", { required: "NSector es requerido" })}
            >
              <option className="text-sm" value="">
                Seleccione sector
              </option>
              {React.Children.toArray(
                sector.map((e) => (
                  <option className="text-sm" value={e.name}>
                    {e.name}
                  </option>
                ))
              )}
            </select>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
              
              </div>
            </IconContext.Provider>
            {errors.NSector && (
              <span className="text-xs text-start text-red-500">
                {errors.NSector.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NSocio"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              Socio implementador{" "}
            </label>

            <select
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NSocio &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NSocio", {
                required: "Socio implementador es requerido",
              })}
            >
              <option defaultValue="default">Seleccione socio</option>
              {React.Children.toArray(
                Object.keys(socio).map((pais, index) => (
                  <optgroup key={index} label={pais}>
                    {renderOptions(socio[pais])}
                  </optgroup>
                ))
              )}
            </select>
            <IconContext.Provider value={{ color: "grey", size: "13px" }}>
              <div
                style={{ marginTop: "-12%", marginLeft: "87%" }}
                className="text-1xl relative"
              >
                
              </div>
            </IconContext.Provider>
            {errors.NSocio && (
              <span className="text-xs text-start text-red-500">
                {errors.NSocio.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NTelefono"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Teléfono{" "}
            </label>
            <Controller
              name="NTelefono"
              control={control}
              className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                errors.NTelefono &&
                "focus:border-red-500 focus:ring-red-500 border-red-500"
              }`}
              {...register("NTelefono", {
                message:
                  "Número de teléfono no puede ser inferior ó mayor a 9 dígitos",
                pattern: "/^[0-9]+$/i",
              })}
              rules={{ validate: (value) => isValidPhoneNumber(value) }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  international
                  defaultCountry={countryCode}
                  value={value}
                  onChange={onChange}
                  rules={{ required: true }}
                />
              )}
            />
            {errors["NTelefono"] && (
              <span className="text-xs text-start text-red-500">
                Número de teléfono es requerido
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NPais"
              className="block bg-white flex item-center justify-between text-sm font-semibold text-start rounded secondary-title
              "
            >
              País{" "}
            </label>
            <div className="relative">
              <select
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NPais &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NPais", { required: "País es requerido" })}
              >
                <option value="">Seleccione país</option>
                {React.Children.toArray(
                  country.map((e) => (
                    <option className="text-sm" value={e.name}>
                      {e.name}
                    </option>
                  ))
                )}
              </select>
              <IconContext.Provider value={{ color: "grey", size: "13px" }}>
                <div
                  style={{ marginTop: "-12%", marginLeft: "87%" }}
                  className="text-1xl relative"
                >
                
                </div>
              </IconContext.Provider>
            </div>
            {errors.NPais && (
              <span className="text-xs text-start text-red-500">
                {errors.NPais.message}
              </span>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NWeb"
              className="block text-sm font-semibold text-start secondary-title"
            >
              Página web{" "}
            </label>
            <div>
              <input
                type="text"
                name="NWeb"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NWeb &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("NWeb", {
                  minLength: {
                    value: 2,
                    message: "La página web debe tener al menos 2 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "La página web debe tener como máximo 100 caracteres",
                  },
                  pattern: true,
                })}
                placeholder="Página web"
              />
            </div>
            {errors.NWeb && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NWeb.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="first_name"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Nombre{" "}
            </label>
            <div>
              <input
                type="text"
                name="first_name"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.first_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("first_name", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El nombre debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El nombre debe tener como máximo 100 caracteres",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Nombre"
              />
            </div>
            {errors.first_name && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.first_name.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="lastname"
              className="block text-sm font-semibold text-start secondary-title
                "
            >
              Apellido{" "}
            </label>
            <div>
              <input
                type="text"
                name="last_name"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.last_name &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("last_name", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El apellido debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El apellido debe tener como máximo 100 caracteres",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Apellido"
              />
            </div>
            {errors.last_name && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.last_name.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="cargo"
              className="block text-sm text-start font-semibold  secondary-title"
            >
              Cargo{" "}
            </label>
            <div>
              <input
                type="name"
                name="cargo"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.cargo &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                {...register("cargo", {
                  required: "Éste campo es obligatorio.",
                  minLength: {
                    value: 3,
                    message: "El cargo debe tener al menos 3 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "El cargo debe tener como máximo 100 caracteres",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
                placeholder="Cargo"
              />
            </div>
            {errors.cargo && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.cargo.message}
                </span>
              </div>
            )}
          </div>

          <div className="mb-2 flex flex-col">
            <label
              htmlFor="NAncla"
              className="block text-sm text-start font-semibold  secondary-title"
            >
              {" "}
              Empresa ancla (opcional)
            </label>
            <div>
              <input
                type="text"
                name="NAncla"
                className={`block w-full px-3 py-2 mt-2  bg-white  bg-input  placeholder-inputs focus:outline-none placeholder:placeholder-inputs${
                  errors.NAncla &&
                  "focus:border-red-500 focus:ring-red-500 border-red-500"
                }`}
                placeholder="Empresa ancla"
                {...register("NAncla", {
                  minLength: {
                    value: 2,
                    message: "Empresa ancla debe tener al mennos 2 caracteres",
                  },
                  maxLength: {
                    value: 100,
                    message: "Empresa ancla debe tener como máximo 100 caracteres",
                  },
                  pattern: "/^[A-Za-z]+$/i",
                })}
              />
            </div>
            {errors.NAncla && (
              <div className="flex py-0.5 gap-0.5">
                <TiWarningOutline className="text-red-500" />
                <span className="text-xs  text-start  text-red-500">
                  {errors.NAncla.message}
                </span>
              </div>
            )}
          </div>

          <div className="btn-register px-3 py-4 tracking-wide  text-white font-semibold focus:bg-color-btn transition-colors duration-200 transform">
            {" "}

            <button
              type="submit"
              disabled={!formState.isValid}
              value="Registrarse"
              className="boxshadow disabled:opacity-50"
            >
              Registrarse
            </button>
          </div>
        </form>
      </div>
      <Toaster position="top-right" />
      <Footer />
    </>
  );
};
